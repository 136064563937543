import Delaunator from "delaunator";
import { Point } from ".";

function edgesOfTriangle(t) {
  return [3 * t, 3 * t + 1, 3 * t + 2];
}
function triangleOfEdge(e) {
  return Math.floor(e / 3);
}
function nextHalfedge(e) {
  return e % 3 === 2 ? e - 2 : e + 1;
}
function prevHalfedge(e) {
  return e % 3 === 0 ? e + 2 : e - 1;
}

export interface TriangulationData {
  from: number;
  to: number;
  distance: number;
}

//
// https://mapbox.github.io/delaunator/
//
export function createTriangulation(points: [number, number][]) {
  return Delaunator.from(points);
}

export function getEdgeConnections(
  delaunator: Delaunator,
  points: [number, number][]
) {
  const connections: TriangulationData[] = [];
  for (let e = 0; e < delaunator.triangles.length; ++e) {
    if (e > delaunator.halfedges[e]) {
      const i = delaunator.triangles[e];
      const j = delaunator.triangles[nextHalfedge(e)];
      const p1 = points[i];
      const p2 = points[j];
      // gDots.moveTo(p1[0], p1[1]);
      // gDots.lineTo(p2[0], p2[1]);
      const distance = Point.distanceSquared(
        Point.fromArray(p1),
        Point.fromArray(p2)
      );
      connections.push({ from: i, to: j, distance });
    }
  }

  connections.sort((a, b) => a.distance - b.distance);
  return connections;
}
