import * as PIXI from "pixi.js";
import { Scene } from "./Scene";
import { FractalNoiseTest as FractalNoiseTest } from "./controls/FractalNoiseTest";
import { WindowControl } from "./controls/WindowControl";
import { RotationTestControl } from "./controls/RotationTestControl";
import { RoomNoiseTestControl } from "./controls/RoomNoiseTestControl";
import { DelaunatorControl } from "./controls/DelaunatorControl";
import { PolyRoomTestControl } from "./controls/PolyRoom";
import { SimplexNoiseTest } from "./controls/SimplexNoiseTest";

const PADDING = 20;

class PlaygroundScene implements Scene {
  private readonly _root: PIXI.Container;
  //   private viewport: Viewport;

  private windows: WindowControl[] = [];
  private simplexNoiseControl: SimplexNoiseTest;
  private fractalNoiseControl: FractalNoiseTest;
  private rotationControl: RotationTestControl;
  private roomNoiseControl: RoomNoiseTestControl;
  private delaunatorControl: DelaunatorControl;
  private polyroomControl: PolyRoomTestControl;
  private controls: Scene[] = [];
  private readonly WindowNames = [
    "Simplex Noise",
    "Fractal Noise",
    "Turn Towards",
    "Reshape Room",
    "Delaunator",
    "Polyroom",
  ];

  public constructor(private app: PIXI.Application) {
    this._root = new PIXI.Container();

    const WINDOW_TITLE_HEIGHT_CHEAT = 28;

    const small = 100;
    const normal = (small + WINDOW_TITLE_HEIGHT_CHEAT) * 2;
    const large = (normal + WINDOW_TITLE_HEIGHT_CHEAT) * 2;
    this.simplexNoiseControl = new SimplexNoiseTest(app, small);
    this.fractalNoiseControl = new FractalNoiseTest(app, small);
    this.rotationControl = new RotationTestControl(app, small);
    this.roomNoiseControl = new RoomNoiseTestControl(app, normal);
    this.delaunatorControl = new DelaunatorControl(app, normal);
    this.polyroomControl = new PolyRoomTestControl(app, large);
    this.controls = [
      this.simplexNoiseControl,
      this.fractalNoiseControl,
      this.rotationControl,
      this.roomNoiseControl,
      this.delaunatorControl,
      this.polyroomControl,
    ];
  }

  public get root() {
    return this._root;
  }

  public activateScene() {
    // this.viewport = new Viewport({
    //   screenWidth: this.app.screen.width,
    //   screenHeight: this.app.screen.height,
    //   worldWidth: 1000,
    //   worldHeight: 1000,
    //   interaction: this.app.renderer.plugins.interaction,
    //   ticker: app.ticker,
    // });

    // this.app.stage.addChild(this.viewport);
    // this.viewport.addChild(this.root);
    // this.viewport.drag().pinch().wheel().decelerate();
    this.controls.forEach((x) => x.activateScene());
    this.windows = this.controls.map(
      (x, i) => new WindowControl(this.app, x, this.WindowNames[i])
    );

    let left = PADDING;
    let top = PADDING;
    let rowHeight = 0;
    this.windows.forEach((window, i) => {
      window.activateScene();
      const control = window.root;
      this._root.addChild(control);
      if (left + control.width < this.app.view.width) {
        control.position.set(left, top);
        left += control.width + PADDING;
        rowHeight = Math.max(rowHeight, control.height);
      } else {
        top += rowHeight + PADDING;
        left = PADDING;
        control.position.set(left, top);
        left += control.width + PADDING;
        rowHeight = control.height;
      }
    });

    return this._root;
  }

  public deactivateScene() {
    this.fractalNoiseControl.deactivateScene();
    this.rotationControl.deactivateScene();
    this.roomNoiseControl.deactivateScene();
    this._root.removeChildren();
  }
}

export default PlaygroundScene;
