import * as PIXI from "pixi.js";
import { Scene } from "../Scene";
import { createButton } from "./Button";
import { createUndugFloorSprite, pebbleSprite } from "../helpers/assetSprites";
import { Noise } from "../../../GameMath";
import { makeRandom, Random } from "../../../utils/random";

const deformWalls = (
  getNoise: () => number[],
  roomSize: number,
  wallScale: number,
  wallThickness: number,
  sizeDiff: number
) => {
  const edgeTop = getNoise();
  const edgeBottom = getNoise();

  const shadow = new PIXI.Graphics();
  {
    const x = sizeDiff;
    edgeTop.forEach((d, i) => {
      const topBorder = wallThickness / 2 - d * wallScale;
      sizeDiff + roomSize - (wallThickness / 2 - d * wallScale);
      shadow
        .moveTo(x + i, topBorder)
        .lineStyle({ color: 0, width: 1, alpha: 0.7 })
        .lineTo(x + i, topBorder + 2)
        .lineStyle({ color: 0, width: 1, alpha: 0.6 })
        .lineTo(x + i, topBorder + 6)
        .lineStyle({ color: 0, width: 1, alpha: 0.4 })
        .lineTo(x + i, topBorder + 12)
        .lineStyle({ color: 0, width: 1, alpha: 0.2 })
        .lineTo(x + i, topBorder + 24);
    });
    edgeBottom.forEach((d, i) => {
      const bottomBorder =
        sizeDiff + roomSize - (wallThickness / 2 - d * wallScale);
      shadow
        .moveTo(x + i, bottomBorder)
        .lineStyle({ color: 0, width: 1, alpha: 0.7 })
        .lineTo(x + i, bottomBorder - 2)
        .lineStyle({ color: 0, width: 1, alpha: 0.6 })
        .lineTo(x + i, bottomBorder - 6)
        .lineStyle({ color: 0, width: 1, alpha: 0.4 })
        .lineTo(x + i, bottomBorder - 12)
        .lineStyle({ color: 0, width: 1, alpha: 0.2 })
        .lineTo(x + i, bottomBorder - 24);
    });
    shadow.lineStyle();
  }
  shadow.pivot.set(roomSize / 2, roomSize / 2);

  const mask = new PIXI.Graphics();
  {
    const x = sizeDiff;
    // const y = sizeDiff + roomSize;
    mask.lineStyle({ color: 0xffffff, width: 1 });
    edgeTop.forEach((d, i) => {
      mask
        .moveTo(x + i, roomSize / 2)
        .lineTo(x + i, wallThickness / 2 - d * wallScale);
    });
    mask.lineStyle();
  }
  {
    const x = sizeDiff;
    const y = sizeDiff + roomSize;
    mask.lineStyle({ color: 0xffffff, width: 1 });
    edgeBottom.forEach((d, i) => {
      mask
        .moveTo(x + i, roomSize / 2)
        .lineTo(x + i, y - wallThickness / 2 + d * wallScale);
    });
    mask.lineStyle();
  }
  mask.pivot.set(roomSize / 2, roomSize / 2);
  return { mask, shadow };
};

export class RoomNoiseTestControl implements Scene {
  private _root: PIXI.Container;
  private readonly height: number;
  private pebbleSprite?: PIXI.TilingSprite;
  private rockSprite?: PIXI.TilingSprite;
  private random: Random;

  constructor(
    private app: PIXI.Application,
    private width = 256,
    height?: number
  ) {
    this.height = height ?? this.width;
    this._root = new PIXI.Container();
    this.random = makeRandom();
  }

  public get root() {
    return this._root;
  }

  public activateScene() {
    this.pebbleSprite = pebbleSprite(this.width, this.height);
    this.rockSprite = createUndugFloorSprite(this.width, this.height);
    this.showRoomTest();
  }

  public deactivateScene() {
    this._root?.removeChildren();
    this?.pebbleSprite?.destroy();
    this.pebbleSprite = undefined;
    this?.rockSprite?.destroy();
    this.rockSprite = undefined;
  }

  private showRoomTest() {
    const ROOM_SIZE = this.width;
    const OVERLAP = Math.floor(this.height * 0.2);
    const WALL_SCALE = 8;
    const SIZE_DIFF = 0;

    const container = new PIXI.Container();
    this._root.addChild(container);

    const noiseOptions = {
      frequency: 0.03,
      octaves: 3,
    };

    let deformation = deformWalls(
      () =>
        Noise.getRandomFractal1D(this.random.engine, ROOM_SIZE, noiseOptions),
      ROOM_SIZE,
      WALL_SCALE,
      OVERLAP,
      SIZE_DIFF
    );

    container.addChild(this.rockSprite!);
    container.addChild(this.pebbleSprite!);
    container.addChild(deformation.mask);
    container.addChild(deformation.shadow);
    this.pebbleSprite!.mask = deformation.mask;

    const button = createButton("Reset", () => {
      container.removeChild(deformation.mask);
      container.removeChild(deformation.shadow);
      deformation = deformWalls(
        () =>
          Noise.getRandomFractal1D(this.random.engine, ROOM_SIZE, noiseOptions),
        ROOM_SIZE,
        WALL_SCALE,
        OVERLAP,
        SIZE_DIFF
      );
      container.addChild(deformation.mask);
      container.addChild(deformation.shadow);
      this.pebbleSprite!.mask = deformation.mask;
    });
    button.root.position.set(0, ROOM_SIZE * 0.2);
    container.addChild(button.root);
  }
}
