export const BIG_MAP = 501;
export const PHYSICS_TIME_SLICE = 1;
export const PHYSICS_CHAMFER_RADIUS = 0;
export const ROOM_BATCH_SIZE = 500;
export const PHYSICS_SLOP_ADJ = 0.25;
export const HALL_WIDTH = 3;

export interface DungeonGenerationOptions {
  RoomCount: number;
  RotationRange: number; // (in Radians) Will be used as -x to +x
  MinRoomSize: number;
  MaxRoomSize: number;
  RoomSpacingScale: number; // Amount of spacing between the rooms (1.0 is touching, <1.0 is more room)
  RoomBorderSize: number; // Thickness of the outside border
  SpawnDensity: number; // 0 = Spawn all objects on top of each other, .5 is close, 1 is cozy, bigger is more space
}

export const DefaultDungeonGenerationOptions: DungeonGenerationOptions = {
  RoomCount: 25,
  RotationRange: 0,
  MinRoomSize: 8,
  MaxRoomSize: 30,
  RoomSpacingScale: 0.85,
  RoomBorderSize: 1,
  SpawnDensity: 0.6,
};

export const getRenderDelayMs = (ROOM_COUNT: number) =>
  Math.floor(3000 / ROOM_COUNT);

export const getMaxPhysicsIterations = (ROOM_COUNT: number) =>
  Math.floor(ROOM_COUNT * 2);

export const getPhysicsSlop = (ROOM_COUNT: number) =>
  ROOM_COUNT >= BIG_MAP ? -PHYSICS_SLOP_ADJ * 4 : 0;

export const getSpawnCircularRegion = (
  ROOM_COUNT: number,
  MAX_ROOM_SIZE: number
) => Math.sqrt(ROOM_COUNT * Math.PI) * MAX_ROOM_SIZE;

const MID = (min: number, max: number) => (max - min) / 2 + 1;

export const getSpawnRectangleRegion = (
  roomCount: number,
  min: number,
  max: number,
  density: number
) => ({
  width: Math.sqrt(roomCount * Math.pow(MID(min, max), 2)) * density,
  height: Math.sqrt(roomCount * Math.pow(MID(min, max), 2)) * density,
});
