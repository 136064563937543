import { Polygon, Vertex } from "./types";

export function create(
  sides: number,
  radius: number = 0,
  startAngle: number | undefined = undefined
): Polygon {
  sides = Math.max(3, sides);
  if (startAngle === undefined) {
    if (sides === 4) {
      startAngle = -Math.PI / 4;
    } else {
      startAngle = sides % 2 === 0 ? 0 : -Math.PI / 2;
    }
  }
  const angle = (2 * Math.PI) / sides;
  const vertices: Vertex[] = [];
  for (let i = 0; i < sides; ++i) {
    vertices.push({
      x: radius * Math.cos(startAngle + angle * i),
      y: radius * Math.sin(startAngle + angle * i),
    });
  }
  return vertices;
}
