import * as RND from "random-js";

export function boxMullerNormals(engine: RND.Engine) {
  // Polar Box-Muller Transform
  // https://spin.atomicobject.com/2019/09/30/skew-normal-prng-javascript/
  let a: number, b: number;
  while (!a) a = RND.realZeroToOneExclusive(engine);
  while (!b) b = RND.realZeroToOneExclusive(engine);
  const r = Math.sqrt(-2 * Math.log(a));
  const w = 2.0 * Math.PI * b;
  return [r * Math.cos(w), r * Math.sin(w)];
}

export function window<T>(fn: () => T[]) {
  let cache = [];
  let index = 0;

  return function () {
    if (cache.length <= index) {
      cache = fn();
      index = 0;
    }

    if (!cache.length) {
      return undefined;
    } else {
      return cache[index++];
    }
  };
}

export function makeRandom(seed?: number[]) {
  let actualSeed =
    seed == undefined || seed.length === 0 ? RND.createEntropy() : seed;
  const engine = RND.MersenneTwister19937.seedWithArray(actualSeed);
  const normalWindow = window(() => boxMullerNormals(engine));

  return {
    seed: actualSeed,
    engine,
    integer: (min: number, max: number) => RND.integer(min, max)(engine),
    zeroToOneInclusive: () => RND.realZeroToOneInclusive(engine),
    zeroToOneExclusive: () => RND.realZeroToOneExclusive(engine),
    shuffle: <T>(data: ArrayLike<T>, downTo?: number): T[] =>
      RND.shuffle(engine, data as any, downTo),
    float: (min: number, max: number, inclusive = false) =>
      RND.real(min, max, inclusive)(engine),
    normal: () => {
      return normalWindow();
    },
  };
}

export type Random = ReturnType<typeof makeRandom>;
