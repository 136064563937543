import * as PIXI from "pixi.js";
import { smallDoor, woodSlats } from "../../../assets/loader";
import { Corridor, Room } from "../../DungeonGeneration";
import { Point } from "pixi.js";
import { Node } from "../../DungeonGeneration/navtree";
import { createBrickWallSprite, pebbleSprite } from "../helpers/assetSprites";
import { Random } from "../../../utils/random";
import { Noise } from "../../../GameMath";

interface WallDeformationOptions {
  borderSize: number; // The world coordinate size of the room edge to deform.
  resolution: number; // The number of samples per world coordinate to use.
  smoothness: number; // The roughness of the wall (.01 is flat)
}

const deformWalls = (
  app: PIXI.Application,
  roomWidth: number,
  roomHeight: number,
  random: Random,
  options: Partial<WallDeformationOptions> = {}
) => {
  const width = roomWidth;
  const halfWidth = width / 2;
  const height = roomHeight;
  const halfHeight = height / 2;

  const borderSize = options?.borderSize ?? 1;
  const resolution = options?.resolution ?? 50;
  const smoothness = options?.smoothness ?? 0.01;

  /**
   * The rooms can be any size and their scale is not tied to this logic or pixel counts.
   * For that reason, we need to pick the brush size (effective resolution) as desired.
   * We can't assume a brush width of 1 is 1 pixel, in fact, we know that it isn't.
   *
   * We have to get an exact number of noise samples, so we have to adjust the desired
   * brush to cover the entire span based on a whole number of samples. That means we
   * first get the whole samples, then figure out the actual brush width each sample
   * represents.
   */
  const horizontalSampleCount = Math.floor(width * resolution);
  const horizontalBrushSize = (width - borderSize * 2) / horizontalSampleCount;

  const verticalSampleCount = Math.floor(height * resolution);
  const veticalBrushSize = (height - borderSize * 2) / verticalSampleCount;

  const xLeftmost = -halfWidth + borderSize;
  const yMiddle = -halfHeight + borderSize / 2;

  const noiseOptions = {
    frequency: smoothness,
    octaves: 1,
  };
  const getNoise = (count: number) =>
    Noise.getRandomFractal1D(random.engine, count, noiseOptions).map(
      (x) => x * borderSize
    );

  let mask = new PIXI.Graphics();
  const shadow = new PIXI.Graphics();

  mask.lineStyle({
    width: horizontalBrushSize,
    alignment: 0, // "Inner" alignment
    color: 0x0f0fff, // Any color works, it is ignored when masking
    alpha: 0.5, // Good for debugging, it is ignored when masking
  });

  // TOP
  let edgeNoise = getNoise(horizontalSampleCount);
  for (let i = 0; i < horizontalSampleCount; ++i) {
    const x = xLeftmost + horizontalBrushSize * i;
    const y = yMiddle - (borderSize / 2) * edgeNoise[i];
    mask.moveTo(x, 0);
    mask.lineTo(x, y);
    shadow
      .moveTo(x, y)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.7 })
      .lineTo(x, y + horizontalBrushSize * 2)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.6 })
      .lineTo(x, y + horizontalBrushSize * 6)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.4 })
      .lineTo(x, y + horizontalBrushSize * 12)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.2 })
      .lineTo(x, y + horizontalBrushSize * 24);
  }

  // BOTTOM
  edgeNoise = getNoise(horizontalSampleCount);
  for (let i = 0; i < horizontalSampleCount; ++i) {
    const x = xLeftmost + horizontalBrushSize * i;
    const y = -yMiddle - (borderSize / 2) * edgeNoise[i];
    mask.moveTo(x, 0);
    mask.lineTo(x, y);

    shadow
      .moveTo(x, y)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.7 })
      .lineTo(x, y - horizontalBrushSize * 2)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.6 })
      .lineTo(x, y - horizontalBrushSize * 6)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.4 })
      .lineTo(x, y - horizontalBrushSize * 12)
      .lineStyle({ color: 0, width: horizontalBrushSize, alpha: 0.2 })
      .lineTo(x, y - horizontalBrushSize * 24);
  }

  // LEFT
  const topMost = -halfHeight + borderSize;
  const xMiddle = halfWidth - borderSize / 2;
  edgeNoise = getNoise(verticalSampleCount);
  for (let i = 0; i < verticalSampleCount; ++i) {
    const y = topMost + veticalBrushSize * i;
    const x = -xMiddle + (borderSize / 2) * edgeNoise[i];
    mask.moveTo(0, y);
    mask.lineTo(x, y);

    shadow
      .moveTo(x, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.7 })
      .lineTo(x + horizontalBrushSize * 2, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.6 })
      .lineTo(x + horizontalBrushSize * 6, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.4 })
      .lineTo(x + horizontalBrushSize * 12, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.2 })
      .lineTo(x + horizontalBrushSize * 24, y);
  }

  // RIGHT
  edgeNoise = getNoise(verticalSampleCount);
  for (let i = 0; i < verticalSampleCount; ++i) {
    const y = topMost + veticalBrushSize * i;
    const x = xMiddle - (borderSize / 2) * edgeNoise[i];
    mask.moveTo(0, y);
    mask.lineTo(x, y);

    shadow
      .moveTo(x, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.7 })
      .lineTo(x - horizontalBrushSize * 2, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.6 })
      .lineTo(x - horizontalBrushSize * 6, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.4 })
      .lineTo(x - horizontalBrushSize * 12, y)
      .lineStyle({ color: 0, width: veticalBrushSize, alpha: 0.2 })
      .lineTo(x - horizontalBrushSize * 24, y);
  }

  return { mask, shadow };
};

export function makeRoom(app: PIXI.Application, room: Room, random: Random) {
  const wall = createBrickWallSprite(room.box.width, room.box.height, 0.02);
  wall.pivot.set(room.box.width / 2, room.box.height / 2);
  // wall.position.set(-room.box.width / 2, -room.box.height / 2);
  // wall.position.set(1, 1);

  const floor = pebbleSprite(room.box.width, room.box.height, 0.004);
  wall.pivot.set(room.box.width / 2, room.box.height / 2);
  // floor.position.set(-room.box.width / 2 + 1, -room.box.height / 2 + 1);
  // floor.pivot.set(0);
  // floor.position.set(room.box.x + 1, room.box.y + 1);

  const rval = new PIXI.Container();
  // rval.addChild(wall);
  rval.addChild(floor);

  let deformation = deformWalls(app, room.box.width, room.box.height, random);

  rval.addChild(deformation.mask);
  floor.mask = deformation.mask;
  rval.addChild(deformation.shadow);

  return rval;
}

export function createSmallDoor(position?: Point) {
  const door = new PIXI.Sprite(PIXI.Texture.from(smallDoor));
  door.scale.set(0.18);
  if (position) {
    door.position.set(position.x, position.y);
  }
  return door;
}

export function makeCorridor(corridor: Corridor) {
  const from = new PIXI.Point(corridor.from.edge.x, corridor.from.edge.y);
  const to = new PIXI.Point(corridor.to.edge.x, corridor.to.edge.y);

  if (corridor?.direction === "diagonal") {
    return new PIXI.Graphics()
      .lineStyle({ color: 0xd2a260, width: corridor.width })
      .moveTo(from.x, from.y)
      .lineTo(to.x, to.y);
  } else {
    const vDiff = from.y - to.y;
    const vertical = Math.abs(vDiff) > Number.EPSILON;
    const hDiff = from.x - to.x;

    const con = new PIXI.Container();
    const bridge = new PIXI.Graphics();
    if (vertical) {
      bridge
        .beginTextureFill({
          texture: PIXI.Texture.from(woodSlats),
          matrix: PIXI.Matrix.IDENTITY.scale(0.01, 0.01),
        })
        .drawRect(from.x - corridor.width / 2, from.y, corridor.width, -vDiff)
        .endFill();
      const door1 = createSmallDoor(from);
      door1.anchor.set(0.5, vDiff > 0 ? 0 : 1);

      const door2 = createSmallDoor(to);
      door2.anchor.set(0.5, vDiff > 0 ? 1 : 0);

      con.addChild(bridge);
      con.addChild(door1);
      con.addChild(door2);
    } else {
      bridge
        .beginTextureFill({
          texture: PIXI.Texture.from(woodSlats),
          matrix: PIXI.Matrix.IDENTITY.scale(0.01, 0.01).rotate(Math.PI / 2),
        })
        .drawRect(from.x, from.y - corridor.width / 2, -hDiff, corridor.width)
        .endFill();
      const door1 = createSmallDoor(from);
      door1.anchor.set(0.5, hDiff > 0 ? 0 : 1);
      door1.rotation = -Math.PI / 2;

      const door2 = createSmallDoor(to);
      door2.anchor.set(0.5, hDiff > 0 ? 1 : 0);
      door2.rotation = -Math.PI / 2;

      con.addChild(bridge);
      con.addChild(door1);
      con.addChild(door2);
    }
    return con;
  }
}

export function makeNavPoints(root: Node[]) {
  const NAV_COLOR = 0x00ffff;
  const NAV_WIDTH = 0.2;
  const NAV_ALPHA = 0.2;
  const g = new PIXI.Graphics().beginFill(NAV_COLOR, NAV_ALPHA);
  for (const node of root) {
    g.drawCircle(node.room.center.x, node.room.center.y, NAV_WIDTH);
    g.lineStyle({ width: NAV_WIDTH, color: NAV_COLOR });
    node.links.forEach((link) => {
      g.moveTo(node.room.center.x, node.room.center.y).lineTo(
        link.fromPoint.x,
        link.fromPoint.y
      );
      g.moveTo(link.fromPoint.x, link.fromPoint.y).lineTo(
        link.toPoint.x,
        link.toPoint.y
      );
    });
    g.lineStyle();
  }
  g.endFill();
  return g;
}
