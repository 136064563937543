export class UnionFind {
  private readonly solution: Int32Array;

  public constructor(candidateCount: number) {
    this.solution = new Int32Array(candidateCount);
    this.solution.forEach((I, i) => (this.solution[i] = -1));
  }

  public makeSet(x: number, y?: number) {
    this.solution[x] = x;
    if (y !== undefined) {
      this.solution[y] = x;
    }
  }

  public union(x: number, y: number) {
    let next = this.solution[y];
    this.solution[y] = x;
    while (this.solution[next] != x) {
      next = this.solution[next];
      this.solution[next] = x;
    }
  }

  public isSameSet(x: number, y: number) {
    return this.solution[x] === this.solution[y];
  }

  public findSet(x: number) {
    let set = this.solution[x];
    if (set === x || set === -1) {
      return set;
    }

    do {
      x = set;
      set = this.solution[x];
    } while (set !== x);
    return set;
  }
}
