import * as PIXI from "pixi.js";
import { Scene } from "./Scene";

const BACKGROUND_COLOR = 0x000020;

export function createPixiApp(
  parent: HTMLElement,
  options?: Partial<{
    backgroundColor: number;
  }>
) {
  const _options = {
    backgroundColor: BACKGROUND_COLOR,
    ...options,
  };

  const app = new PIXI.Application({
    backgroundColor: _options.backgroundColor,
    resolution: window.devicePixelRatio,
    autoDensity: true,
    resizeTo: parent,
    antialias: true,
  });

  parent.replaceChild(app.view, parent.lastElementChild!); // Hack for parcel HMR
  return app;
}

export class SceneManager {
  private currentScene?: Scene;

  constructor(private app: PIXI.Application) {}

  public setScene(scene?: Scene) {
    if (this.currentScene) {
      const root = this.currentScene.root;
      this.currentScene.deactivateScene();
      this.app.stage.removeChild(root);
    }

    this.currentScene = scene;
    if (this.currentScene) {
      this.currentScene.activateScene();
      this.app.stage.addChild(this.currentScene.root);
    }
  }
}
