import * as phys from "matter-js";
import * as AABB from "../../GameMath/aabb";
import { Delay } from "../../utils/delay";
import { Point } from "../../GameMath";
import { Random } from "../../utils/random";
import { Corridor, RenderCallack, Room } from "./dungeon";
import { angleDistanceToPoint } from "./dungeonGenerator";
import {
  getMaxPhysicsIterations,
  ROOM_BATCH_SIZE,
  PHYSICS_TIME_SLICE,
  DungeonGenerationOptions,
  DefaultDungeonGenerationOptions,
  getSpawnRectangleRegion,
  getRenderDelayMs,
} from "./settings";

export const getBoundingBoxFromPhysicsBody = (geometry: phys.Body) => {
  const { x: xMin, y: yMin } = geometry.bounds.min;
  const { x: xMax, y: yMax } = geometry.bounds.max;
  const width = xMax - xMin;
  const height = yMax - yMin;
  return AABB.create(xMin, yMin, width, height);
};

export async function generateRooms(
  rnd: Random,
  options?: Partial<DungeonGenerationOptions>,
  renderCallback?: RenderCallack
) {
  const settings = { ...DefaultDungeonGenerationOptions, ...options };
  const SpawnRectangle = getSpawnRectangleRegion(
    settings.RoomCount,
    settings.MinRoomSize,
    settings.MaxRoomSize,
    settings.SpawnDensity
  );
  const MaxPhysicsInterations = getMaxPhysicsIterations(settings.RoomCount);

  const getRoomSize = () =>
    rnd.integer(settings.MinRoomSize, settings.MaxRoomSize);

  const getRadialRoomPosition = (radius: number) =>
    angleDistanceToPoint(
      rnd.zeroToOneInclusive() * Math.PI * 2,
      rnd.integer(0, radius)
    );

  const getBoxRoomPosition = () =>
    Point.create(
      rnd.integer(-SpawnRectangle.width, SpawnRectangle.width),
      rnd.integer(-SpawnRectangle.height, SpawnRectangle.height)
    );

  const randomRoom = () => ({
    width:
      getRoomSize() + (settings.RoomBorderSize * 2) / settings.RoomSpacingScale,
    height:
      getRoomSize() + (settings.RoomBorderSize * 2) / settings.RoomSpacingScale,
    ...getBoxRoomPosition(),
  });

  const roomToPhysicsBody = (x: AABB.IAABB): phys.Body =>
    phys.Bodies.rectangle(x.x, x.y, x.width, x.height, { inertia: Infinity });

  // Make the physics engine position them
  const engine = phys.Engine.create();
  engine.world.gravity.y = 0;

  let collisionCount = 0;
  phys.Events.on(engine, "collisionStart", (event) => {
    collisionCount += event.pairs.length;
    // console.log({ collisionCount });
  });
  phys.Events.on(engine, "collisionEnd", (event) => {
    collisionCount -= event.pairs.length;
    // console.log({ collisionCount });
  });

  const bodies: phys.Body[] = [];

  const bodiesToDungeonRoom = (): {
    rooms: Room[];
    corridors: Corridor[];
  } => ({
    rooms: bodies.map((x) => ({
      vertices: x.vertices.map(Point.clone),
      box: getBoundingBoxFromPhysicsBody(x),
      center: phys.Vertices.centre(x.vertices),
    })),
    corridors: [],
  });

  const animationDelay = () => Delay(getRenderDelayMs(settings.RoomCount));

  let iterations = 0;
  let cheated = 0;
  while (
    (iterations == 0 ||
      collisionCount != 0 ||
      bodies.length < settings.RoomCount) &&
    iterations < MaxPhysicsInterations
  ) {
    if (iterations % 100 === 0 && bodies.length < settings.RoomCount) {
      const newCount = Math.min(
        settings.RoomCount - bodies.length,
        ROOM_BATCH_SIZE
      );
      const more = Array.from({ length: newCount }, randomRoom).map(
        roomToPhysicsBody
      );

      if (settings.RotationRange != 0) {
        more.forEach((x) =>
          phys.Body.rotate(
            x,
            rnd.float(-MaxPhysicsInterations, MaxPhysicsInterations, true)
          )
        );
      }

      more.forEach((x) => phys.Composite.add(engine.world, x));
      bodies.push(...more);
    }

    if (
      //settings.RoomCount >= BIG_MAP &&
      (cheated == 0 && iterations >= settings.RoomCount * 0.2) ||
      (cheated == 1 && iterations >= settings.RoomCount * 0.25) ||
      (cheated == 2 && iterations >= settings.RoomCount * 0.3) ||
      (cheated == 3 && iterations >= settings.RoomCount * 0.35) ||
      (cheated == 4 && iterations >= settings.RoomCount * 0.4)
    ) {
      console.log({ cheated, count: iterations });
      cheated++;
      bodies.forEach((x) => phys.Body.scale(x, 0.97, 0.97));
    }

    phys.Engine.update(engine, PHYSICS_TIME_SLICE);
    if (renderCallback) {
      renderCallback(bodiesToDungeonRoom());
      await animationDelay();
    }

    if (iterations % 100 === 0) {
      console.info("Placing Rooms", {
        iterations,
        roomCount: bodies.length,
        collisions: collisionCount,
      });
    }

    ++iterations;
  }

  phys.Events.off(engine, null as any, null as any);

  bodies.forEach((x) => {
    phys.Body.scale(x, settings.RoomSpacingScale, settings.RoomSpacingScale);
  });

  const rooms = bodiesToDungeonRoom().rooms;

  return {
    rooms,
    iterations,
    collisions: collisionCount,
  };
}
