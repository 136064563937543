import { Vector } from "..";
import { Polygon, Vertex } from "./types";

export function rotate(
  polygon: Polygon,
  radians: number,
  origin: Vertex = { x: 0, y: 0 }
) {
  const rval: Polygon = [];
  for (let i = 0; i < polygon.length; ++i) {
    const vertex = polygon[i];
    let v = Vector.subtract(vertex, origin);
    v = Vector.rotate(v, radians);
    v = Vector.add(v, origin);
    rval.push(v);
  }
  return rval;
}
