import * as RND from "random-js";
import { makeRectangle, Options } from "./FractalNoise";
import { makeNoise2D } from "./simplex2D";

/**
 * Create a high quality 256 element noise seed.
 * @param random A function providing [0,1) (1 exclusive) numbers
 * @returns A randomized array of each integer value between [0,255] (inclusive)
 */
export function makeNoiseSeed2DHighQuality(random: RND.Engine): Uint8Array {
  const seed = new Uint8Array(256);
  for (let i = 0; i < 256; i++) seed[i] = i;
  RND.shuffle(random, seed as any);
  return seed;
}

export function getRandomFractal1D(
  rnd: RND.Engine,
  count: number,
  options: Partial<Options> = {}
) {
  const seed = makeNoiseSeed2DHighQuality(rnd);
  const simplex = makeNoise2D(seed);
  const data = makeRectangle(1, count, simplex, options);
  return data[0];
}
