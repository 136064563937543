import * as PIXI from "pixi.js";
import { Line } from "../../../GameMath";

export function drawLineSegment(
  line: Line.ILine | undefined,
  options: Partial<PIXI.ILineStyleOptions> = {},
  graphics?: PIXI.Graphics
): PIXI.Graphics {
  const g = graphics ?? new PIXI.Graphics();
  if (line !== undefined) {
    g.lineStyle({
      width: 1,
      color: 0xff00ff,
      ...options,
    })
      .moveTo(line.p1.x, line.p1.y)
      .lineTo(line.p2.x, line.p2.y)
      .lineStyle();
  }
  return g;
}
