import * as PIXI from "pixi.js";
import { Scene } from "../Scene";
import { createButton } from "./Button";
import { Polygon } from "../../../GameMath";
import { createUndugFloorSprite, pebbleSprite } from "../helpers/assetSprites";
import {
  createDeformOptions,
  deformPolygon,
  maskFromDeformation,
  shadowFromDeformation,
} from "../helpers/deformation";

export class PolyRoomTestControl implements Scene {
  private _root: PIXI.Container;
  private readonly height: number;
  private pebbleSprite?: PIXI.TilingSprite;
  private rockSprite?: PIXI.TilingSprite;

  constructor(
    private app: PIXI.Application,
    private width = 256,
    height?: number
  ) {
    this.height = height ?? this.width;
    this._root = new PIXI.Container();
  }

  public get root() {
    return this._root;
  }

  public activateScene() {
    this.pebbleSprite = pebbleSprite(this.width, this.height);
    this.rockSprite = createUndugFloorSprite(this.width, this.height);

    const container = new PIXI.Container();
    this._root.addChild(container);

    const background = new PIXI.Graphics();
    background
      .beginFill(0xffffff)
      .drawRect(0, 0, this.width, this.height)
      .endFill();
    background.pivot.set(this.width / 2, this.height / 2);

    const button = createButton("Reset", () => {});
    button.root.position.set(0, this.height * 0.2);

    container.addChild(background);
    container.addChild(button.root);

    this.doTest();
  }

  public deactivateScene() {
    this._root?.removeChildren();
    this?.pebbleSprite?.destroy();
    this.pebbleSprite = undefined;
    this?.rockSprite?.destroy();
    this.rockSprite = undefined;
  }

  private doTest() {
    const numberOfSides = 5;
    const polySize = 10;
    const displaySize = Math.min(this.width, this.height) * 0.9;
    const polygon = Polygon.create(numberOfSides, polySize / 2);
    const options = createDeformOptions(polygon, 0.05, 50, 8, 2);
    const deformedPolygon = deformPolygon(polygon, options);
    const collision = Polygon.scale(polygon, 0.9);
    const mask = maskFromDeformation(deformedPolygon, displaySize / polySize);
    const shadow = shadowFromDeformation(
      deformedPolygon,
      displaySize / polySize,
      1 / (displaySize / polySize)
    );

    this._root.addChild(this.rockSprite);
    this._root.addChild(this.pebbleSprite);
    this._root.addChild(mask);
    this._root.addChild(shadow);
    this.pebbleSprite.mask = mask;

    const collisionPolygon = new PIXI.Graphics()
      .lineStyle({ width: (polySize / displaySize) * 2, color: 0x00ff00 })
      .drawPolygon(collision as PIXI.ObservablePoint[]);
    collisionPolygon.scale.set(displaySize / polySize);
    this._root.addChild(collisionPolygon);
  }
}
