import * as PIXI from "pixi.js";
import { render } from "preact";
import { Polygon } from "../../../GameMath";
import { pebbleSprite } from "../helpers/assetSprites";
import {
  deformPolygon,
  maskFromDeformation,
  shadowFromDeformation,
} from "../helpers/deformation";

export function createPolygonRoom(
  app: PIXI.Application,
  numberOfSides: number,
  screenScale: number,
  singlePixel: number,
  radius = 1.0
) {
  const borderSizePolyUnits = 0.2;
  const borderSize = borderSizePolyUnits * screenScale;
  const polygon = Polygon.create(numberOfSides, radius * screenScale);
  const collision = Polygon.scale(
    polygon,
    (radius - borderSizePolyUnits) / radius
  );

  const deformedPolygon = deformPolygon(polygon, {
    borderSize,
    samplesPerUnit: singlePixel / 5,
    smoothness: 0.03,
    octaves: 2,
  });

  const fullSize = radius * 2 * screenScale;
  const fullRegion = new PIXI.Rectangle(
    -fullSize / 2,
    -fullSize / 2,
    fullSize,
    fullSize
  );

  const mask = maskFromDeformation(deformedPolygon, 1);
  // const maskSprite = new PIXI.Sprite(
  //   app.renderer.generateTexture(mask, PIXI.SCALE_MODES.LINEAR, 1, fullRegion)
  // );
  // maskSprite.anchor.set(0.5);

  const shadow = shadowFromDeformation(deformedPolygon, 1, singlePixel);
  // const shadowSprite = new PIXI.Sprite(
  //   app.renderer.generateTexture(shadow, PIXI.SCALE_MODES.LINEAR, 1, fullRegion)
  // );
  // shadowSprite.anchor.set(0.5);

  const container = new PIXI.Container();
  const floorSprite = pebbleSprite(fullSize, fullSize);
  floorSprite.mask = mask;
  container.addChild(floorSprite);
  container.addChild(mask);
  container.addChild(shadow);
  container.cacheAsBitmap = true;

  // const flattened = new PIXI.Sprite(
  //   app.renderer.generateTexture(
  //     container,
  //     PIXI.SCALE_MODES.LINEAR,
  //     1,
  //     fullRegion
  //   )
  // );
  // flattened.anchor.set(0.5);

  // const renderer = app.renderer;
  // const renderTexture = PIXI.RenderTexture.create({
  //   width: fullSize,
  //   height: fullSize,
  // });
  // container.position.set(fullSize / 2);
  // renderer.render(container, { renderTexture });
  // const flattened = new PIXI.Sprite(renderTexture);
  // flattened.anchor.set(0.5);

  return {
    sprite: container,
    collision: collision,
    originalPolygon: polygon,
  };
}

export type PolygonRoom = ReturnType<typeof createPolygonRoom>;
