import * as PIXI from "pixi.js";
import { spider } from "../../../assets/loader";

const PlayerProps = {
  resolution: 1024,
  baseColor: 0xffffff,
  baseAlpha: 0.8,
  borderColor: 0xff00a0,
  borderAlpha: 0.7,
  boarderWidthScale: 0.2,
  angleColor: 0xffff00,
  angleAlpha: 0.8,
};

export function makePlayer(app: PIXI.Application, options = PlayerProps) {
  options = { ...PlayerProps, ...options };

  const { resolution } = options;
  const playerBase = new PIXI.Graphics()
    .lineStyle({
      width: resolution * options.boarderWidthScale,
      color: options.borderColor,
      alpha: options.borderAlpha,
    })
    .beginFill(options.baseColor, options.baseAlpha)
    .drawCircle(0, 0, resolution)
    .endFill()
    .lineStyle({
      width: resolution * options.boarderWidthScale,
      color: options.angleColor,
      alpha: options.angleAlpha,
    })
    .arc(0, 0, resolution, (-Math.PI / 3) * 2, -Math.PI / 3, false);
  const baseSprite = new PIXI.Sprite(app.renderer.generateTexture(playerBase));
  baseSprite.anchor.set(0.5);
  baseSprite.width = baseSprite.height = 1;
  baseSprite.angle = 90;

  const sheet = PIXI.BaseTexture.from(spider);
  const frameCount = 19;
  const frameWidth = 120;
  const frameHeight = 148;
  const walkTexture = [];
  for (let i = 0; i < frameCount; i++) {
    walkTexture.push(
      new PIXI.Texture(
        sheet,
        new PIXI.Rectangle(
          (i % 4) * frameWidth,
          Math.floor(i / 4) * frameHeight,
          frameWidth,
          frameHeight
        )
      )
    );
  }

  const mainPlayer = new PIXI.AnimatedSprite(walkTexture, true);
  mainPlayer.anchor.set(0.5);
  mainPlayer.animationSpeed = 1;
  mainPlayer.loop = true;
  mainPlayer.play();
  mainPlayer.scale.set(0.01);
  mainPlayer.angle = 90; // Default position should point right
  console.log(mainPlayer.width);

  const container = new PIXI.Container();
  container.addChild(baseSprite);
  container.addChild(mainPlayer);

  return container;
}
