import { Polygon, Vertex } from "./types";

/**
 * Returns the mean center of the polygon (average of vertices).
 */
export function center(vertices: Polygon): Vertex {
  const weight = vertices.reduce(
    (acc, cur) => ({ x: acc.x + cur.x, y: acc.y + cur.y }),
    { x: 0, y: 0 }
  );
  return { x: weight.x / vertices.length, y: weight.y / vertices.length };
}
