import * as PIXI from "pixi.js";
import { Vector } from "../../../GameMath";
import { IPoint } from "../../../GameMath/point";
import { drawVector } from "../helpers/drawVector";
import { Scene, subscribeToUpdates, SubscriptionManager } from "../Scene";

export class RotationTestControl implements Scene {
  private _root: PIXI.Container;
  private cleanup: () => void;

  public constructor(private app: PIXI.Application, private controlSize = 300) {
    this._root = new PIXI.Container();
  }

  public get root() {
    return this._root;
  }

  public activateScene() {
    const { controlSize } = this;
    const BASE_COLOR = 0x5f5fff;
    const TARGET_LENGTH = (controlSize / 2) * 0.9;
    const TARGET_COLOR = 0xff0000;
    const CHASE_LENGTH = (controlSize / 2) * 0.6;
    const CHASE_COLOR = 0xffff00;
    const FONT_SIZE = controlSize / 10;

    const base = new PIXI.Graphics()
      .beginFill(BASE_COLOR, 1)
      .drawCircle(0, 0, controlSize / 2)
      .endFill();
    base.interactive = true;

    const message = new PIXI.Text("Click the circle", {
      fontFamily: "Arial",
      fontSize: FONT_SIZE,
    });
    message.anchor.set(0.5, 0);
    message.position.set(0, controlSize * 0.1);

    // A vector to "chase" the other one
    let chaseVector = Vector.scale(Vector.clone(Vector.Up), CHASE_LENGTH);

    // The vector to follow/match
    let targetVector = Vector.scale(Vector.clone(Vector.Up), TARGET_LENGTH);

    // Instantly move the target vector to the new angle
    const handleClick = (globalClickPoint: IPoint) => {
      const localClickPoint = base.toLocal(globalClickPoint);
      targetVector = Vector.scale(
        Vector.normalize(localClickPoint),
        TARGET_LENGTH
      );
    };

    (base as any).on("click", (event) => {
      handleClick(Vector.clone(event.data.global));
    });
    (base as any).on("tap", (event) => {
      handleClick(Vector.clone(event.data.global));
    });
    const cleanupInteractions = () => {
      (base as any).off("click");
      (base as any).off("tap");
    };

    const update = (delta = 1) => {
      chaseVector = Vector.turnTowards(chaseVector, targetVector, 0.05 * delta);
      this._root.removeChildren();
      this._root.addChild(base);
      this._root.addChild(message);
      this._root.addChild(drawVector(targetVector, TARGET_COLOR));
      this._root.addChild(drawVector(chaseVector, CHASE_COLOR));
    };

    update();

    const cleanupTicker = subscribeToUpdates(this.app.ticker)(update);
    this.cleanup = () => {
      cleanupTicker();
      cleanupInteractions();
    };
  }

  public deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._root.removeChildren();
  }
}
