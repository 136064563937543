import {
  getAllIntersectingProjections,
  getClosestVerticesBetweenPolygons,
  IntersectionCandidate,
} from ".";
import { Polygon } from "./types";

export function getClosestConnectionBetweenPolygons(
  fromPolygon: Polygon,
  toPolygon: Polygon,
  includeDebug = true
) {
  const closestVertices = getClosestVerticesBetweenPolygons(
    fromPolygon,
    toPolygon
  );
  const aResults = getAllIntersectingProjections(fromPolygon, toPolygon);
  const bResults = getAllIntersectingProjections(toPolygon, fromPolygon);
  const debug = includeDebug
    ? {
        projections: [...aResults.projections, ...bResults.projections],
        segments: [...aResults.segments, ...bResults.segments],
        candidates: [...aResults.candidates, ...bResults.candidates],
      }
    : undefined;

  const candidates: IntersectionCandidate[] = [
    ...aResults.candidates,
    ...bResults.candidates.map((r) => ({
      ...r,
      vertex: r.intersection,
      intersection: r.vertex,
    })),
  ];

  let shortest = {
    p1: fromPolygon[closestVertices.iPolyAClosest],
    p2: toPolygon[closestVertices.iPolyBClosest],
  };
  if (candidates.length) {
    const candidate = candidates.sort((a, b) => a.distance - b.distance)[0];
    if (candidate.distance < closestVertices.distance) {
      shortest = { p1: candidates[0].vertex, p2: candidates[0].intersection };
    }
  }

  return {
    connection: shortest,
    projections: debug?.projections,
    segments: debug?.segments,
    candidates: debug?.candidates,
  };
}
