import { Point, Polygon, Vector } from ".";
import { IPoint } from "./point";

export interface ILine {
  p1: IPoint;
  p2: Point.IPoint;
}

export const create = (x1: number, y1: number, x2: number, y2: number) => ({
  p1: Point.create(x1, y1),
  p2: Point.create(x2, y2),
});

export const clone = (line: ILine) => ({
  p1: Point.clone(line.p1),
  p2: Point.clone(line.p2),
});

export const fromPoints = (p1: Point.IPoint, p2: Point.IPoint) => ({
  p1,
  p2,
});

export const fromVector = (
  v: Vector.Vector,
  base: Vector.Vector,
  length: number
): ILine => ({
  p1: base,
  p2: Vector.add(base, Vector.scale(Vector.normalize(v), length)),
});

export const fromVectorAtOrigin = (
  v: Vector.Vector,
  length?: number
): ILine => {
  if (length) {
    v = Vector.scale(Vector.normalize(v), length);
  }
  return { p1: { x: 0, y: 0 }, p2: v };
};

export const length = (line: ILine) => Point.distance(line.p1, line.p2);

export const translate = (line: ILine, x: number, y: number) =>
  create(line.p1.x + x, line.p1.y + y, line.p2.x + x, line.p2.y + y);

export const normal = (line: ILine, sign = 0): Vector.Vector =>
  sign > 0
    ? Vector.create(-(line.p2.y - line.p1.y), line.p2.x - line.p1.x)
    : Vector.create(line.p2.y - line.p1.y, -(line.p2.x - line.p1.x));

export const intersection = (a: ILine, b: ILine) => {
  const p0 = a.p1;
  const p1 = a.p2;
  const p2 = b.p1;
  const p3 = b.p2;

  const s1_x = p1.x - p0.x;
  const s1_y = p1.y - p0.y;
  const s2_x = p3.x - p2.x;
  const s2_y = p3.y - p2.y;

  const divisor = -s2_x * s1_y + s1_x * s2_y;
  const s = (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) / divisor;
  const t = (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) / divisor;

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    return { x: p0.x + t * s1_x, y: p0.y + t * s1_y };
  }
};

interface PolygonIntersection {
  edge: ILine;
  intersection: Point.IPoint;
}

/**
 * Returns the point on the edge of the polygon that intersects the line.
 * @param line The line
 * @param polygon The polygon
 * @returns The edge and point of intersection or undefined, if they do not intersect
 */
export function intersectsPolygon(
  line: ILine,
  polygon: Polygon.Polygon
): PolygonIntersection | undefined {
  const candidates: PolygonIntersection[] = [];
  for (let i = 0; i < polygon.length; i++) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % polygon.length];
    const x = intersection(line, fromPoints(p1, p2));
    if (x) {
      candidates.push({
        edge: fromPoints(p1, p2),
        intersection: x,
      });
    }
  }

  if (candidates.length === 0) {
    return undefined;
  } else if (candidates.length === 1) {
    return candidates[0];
  } else {
    let closestDistance = Number.MAX_VALUE;
    return candidates.reduce(
      (closest: PolygonIntersection, cur: PolygonIntersection) => {
        const distance = Point.distanceSquared(line.p1, cur.intersection);
        if (distance < closestDistance) {
          closestDistance = distance;
          return cur;
        } else {
          return closest;
        }
      },
      candidates[0]
    );
  }
}

export function midpoint(line: ILine): Point.IPoint {
  return {
    x: line.p1.x + (line.p2.x - line.p1.x) / 2,
    y: line.p1.y + (line.p2.y - line.p1.y) / 2,
  };
}
