import * as PIXI from "pixi.js";
import { Noise } from "../../../GameMath";
import { makeRandom } from "../../../utils/random";
import { loadSeed } from "../../MainApp";
import { Scene, subscribeToUpdates } from "../Scene";

const sampleToColor = (sample: number) => {
  const gray = Math.floor(((sample + 1) / 2) * 255);
  if (gray < 0 || gray > 255) {
    throw new Error("Fix the random scaling " + `${sample}=${gray}`);
  }
  return gray;
};

export class SimplexNoiseTest implements Scene {
  private _root: PIXI.Container;
  private readonly height: number;
  private readonly simplex: Noise.MakeNoise;
  private cleanup: undefined | (() => unknown);

  constructor(
    private app: PIXI.Application,
    private width = 256,
    height?: number
  ) {
    this.height = height ?? this.width;
    this._root = new PIXI.Container();
    const rand_seed = loadSeed();
    const seed = Noise.makeNoiseSeed2DHighQuality(makeRandom(rand_seed).engine);
    this.simplex = Noise.makeNoise2D(seed);
  }

  public get root() {
    return this._root;
  }

  activateScene() {
    this.initializeControl(this.app);
    return this._root;
  }

  deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._root.removeChildren();
  }

  initializeControl(app: PIXI.Application) {
    // Create the noise field
    const { width, height } = this;

    // Generate random noise
    const noiseFrequency = 0.02;

    const getTunedNoise = (options: any = {}) => {
      const frequency = options?.frequency ?? 0.02;
      return (x: number, y: number) => {
        const sample = this.simplex(x * noiseFrequency, y * noiseFrequency);
        return Math.min(Math.max(-1, sample), 1);
      };
    };

    const getNoiseOctave = (x: number, y: number) => {
      return Noise.octave(this.simplex, x, y, noiseFrequency, 2, 0.1);
    };

    const sampleNoiseSimple = getTunedNoise();
    const sampleNoiseOctave = getNoiseOctave;
    let sampleNoise = sampleNoiseSimple;

    let isAnimating = true;
    const toggle = () => {
      sampleNoise =
        sampleNoise === sampleNoiseSimple
          ? sampleNoiseOctave
          : sampleNoiseSimple;
      if (!isAnimating) {
        update(xOffset, yOffset);
      }
    };

    this._root.interactive = true;
    (this._root as any).on("click", toggle);
    (this._root as any).on("tap", toggle);
    const toggleCleanup = () => {
      (this._root as any).off("click", toggle);
      (this._root as any).off("tap", toggle);
    };

    const gLine = new PIXI.Graphics();
    let noiseTexture: PIXI.Texture | undefined;

    const update = (xOffset = 0, yOffset = 0) => {
      if (noiseTexture !== undefined) {
        noiseTexture.destroy();
      }
      this._root.removeChildren();

      // Convert the noise to a grayscale texture
      const bitmap = new Uint8Array(width * height * 3);
      for (let x = 0; x < width; ++x) {
        for (let y = 0; y < height; ++y) {
          const sample = sampleNoise(x + xOffset, y + yOffset);
          const gray = sampleToColor(sample);
          const i = (y * width + x) * 3 + 0;
          bitmap[i] = gray;
          bitmap[i + 1] = gray;
          bitmap[i + 2] = gray;
        }
      }

      noiseTexture = PIXI.Texture.fromBuffer(bitmap, width, height, {
        format: PIXI.FORMATS.RGB,
      });

      // Create a sprite from the texture
      const sprite = PIXI.Sprite.from(noiseTexture);
      sprite.pivot.set(sprite.width / 2, sprite.height / 2);
      this._root.addChild(sprite);

      // Draw a line representing a single row of data
      const middle = 0;
      const left = -width / 2;
      let lineSource = height / 2; // Which row to draw

      const line = gLine
        .clear()
        .lineStyle({ color: 0xffffff, width: 3, alpha: 0.5 })
        .moveTo(left, 0)
        .lineTo(left + width, 0)
        .lineStyle({ color: 0xffff00, width: 2 });
      for (let i = 0; i < width; i++) {
        if (i) {
          line.lineTo(
            left + i,
            middle -
              sampleNoise(i + xOffset, lineSource + yOffset) * (height / 2)
          );
        } else {
          line.moveTo(
            left + i,
            middle -
              sampleNoise(i + xOffset, lineSource + yOffset) * (height / 2)
          );
        }
      }
      this._root.addChild(gLine);
    };

    let xOffset = 0;
    let yOffset = 0;

    update(xOffset, yOffset);

    let frameCount = 0;

    const X_SPEED = 1;
    const Y_SPEED = 1;

    const FRAME_DELAY = 2;
    const loop = (delta: number) => {
      frameCount++;
      if (frameCount % FRAME_DELAY === 0 && isAnimating) {
        xOffset += X_SPEED * delta;
        yOffset += Y_SPEED * delta;
        update(xOffset, yOffset);
      }
    };

    const tickerCleanup = subscribeToUpdates(app.ticker)(loop);
    this.cleanup = () => {
      tickerCleanup();
      toggleCleanup();
    };
  }
}
