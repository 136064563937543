import * as PIXI from "pixi.js";
import { Vector } from "../../../GameMath";

/**
 * Draw a vector to a PIXI.Graphics object.
 * @param vector The vector to draw
 * @param color The color to use
 * @param length The length (in world coordinates) to scale to, or leave blank to draw from 0,0
 * @param fromPoint The origin point to use, or leave blank to start drawing at 0,0
 * @param g Optional PIXI.Graphics to draw to
 * @param scale Scale up or down the arrow head and line size
 * @returns A PIXI object
 */
export const drawVector = (
  vector: Vector.Vector,
  color: number,
  length?: number,
  fromPoint?: Vector.Vector,
  g?: PIXI.Graphics,
  scale = 1
) => {
  if (length) {
    vector = Vector.scale(Vector.normalize(vector), length);
  }

  const arrowAngle = Math.PI / 5;
  const arrowSize = 9 * scale;
  const lineWidth = 3 * scale;
  const reverseVector = Vector.normalize(Vector.rotate(vector, Math.PI));
  const left = Vector.scale(
    Vector.rotate(reverseVector, arrowAngle),
    arrowSize
  );
  const right = Vector.scale(
    Vector.rotate(reverseVector, -arrowAngle),
    arrowSize
  );

  if (fromPoint) {
    vector = Vector.add(fromPoint, vector);
  } else {
    fromPoint = { x: 0, y: 0 };
  }
  const leftPoint = Vector.add(vector, left);
  const rightPoint = Vector.add(vector, right);

  const gr = g ?? new PIXI.Graphics();
  gr.lineStyle({ color, width: lineWidth })
    .beginFill(color)
    .drawCircle(fromPoint.x, fromPoint.y, lineWidth * 1.25)
    .endFill()
    .moveTo(fromPoint.x, fromPoint.y)
    .lineTo(vector.x, vector.y)
    .lineStyle({ color, width: lineWidth * 0.5 })
    .lineTo(leftPoint.x, leftPoint.y)
    .moveTo(rightPoint.x, rightPoint.y)
    .lineTo(vector.x, vector.y)
    .lineStyle();
  return gr;
};
