import { addParam, addParams } from "./urlUtils";

const AUTH_PREFIX = process.env.AUTH_PREFIX;
const AUTH_REGION = process.env.AUTH_REGION;
const AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID;
const AUTH_REDIRECT_URI = process.env.AUTH_REDIRECT_URI;

const CognitoDomain = (prefix: string, region: string) =>
  `https://${prefix}.auth.${region}.amazoncognito.com`;
const CognitoHostedUI = () => CognitoDomain(AUTH_PREFIX, AUTH_REGION);

const withClientId = (client_id = AUTH_CLIENT_ID) => ({
  key: "client_id",
  value: client_id,
});

const withResponseType = (code = "code") => ({
  key: "response_type",
  value: code,
});

const withScope = (
  scope = "aws.cognito.signin.user.admin email openid phone profile"
) => ({ key: "scope", value: scope });

const withRedirectUri = (uri = AUTH_REDIRECT_URI) => ({
  key: "redirect_uri",
  value: uri,
});

const withGrantType = (grant: "authorization_code" | "refresh_token") => ({
  key: "grant_type",
  value: grant,
});

export const hostedPages = {
  signUp: addParams(`${CognitoHostedUI()}/signup/`, [
    withClientId(),
    withRedirectUri(),
    withResponseType(),
    withScope(),
  ]),
  login: addParams(`${CognitoHostedUI()}/login/`, [
    withClientId(),
    withRedirectUri(),
    withResponseType(),
    withScope(),
  ]),
  authorize: addParams(`${CognitoHostedUI()}/authorize/`, [
    withClientId(),
    withRedirectUri(),
    withResponseType(),
    withScope(),
  ]),
  userInfo: addParams(`${CognitoHostedUI()}/userInfo/`, [withClientId()]),
  token: addParams(`${CognitoHostedUI()}/token/`, [
    withClientId(),
    withRedirectUri(),
    withGrantType("authorization_code"),
  ]),
  fromRefresh: addParams(`${CognitoHostedUI()}/token/`, [
    withClientId(),
    withRedirectUri(),
    withGrantType("refresh_token"),
  ]),
};

export const getSignUpUrl = () => hostedPages.signUp;
export const getLoginUrl = () => hostedPages.login;
export const getTokenUrl = (code: string) =>
  addParam(hostedPages.token, { key: "code", value: code });
export const getTokenFromRefreshUrl = (refreshToken: string) =>
  addParam(hostedPages.fromRefresh, {
    key: "refresh_token",
    value: refreshToken,
  });
