import * as PIXI from "pixi.js";
import { Graphics } from "pixi.js";
import { makeRandom } from "../../../utils/random";
import { Scene, subscribeToUpdates } from "../Scene";
import { MinSpanKurskal, Point, Triangulation } from "../../../GameMath";

export class DelaunatorControl implements Scene {
  private _root: PIXI.Container;
  private cleanup: () => unknown;
  private readonly height: number;

  constructor(private app: PIXI.Application, private width = 256, height?) {
    this.height = height ?? this.width;
    this._root = new PIXI.Container();
  }

  public get root() {
    return this._root;
  }

  activateScene() {
    this.initializeControl(this.app);
    return this._root;
  }

  deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._root.removeChildren();
  }

  initializeControl(app: PIXI.Application) {
    const { width, height } = this;

    const POINT_COUNT = Math.sqrt(width);
    const BATCH_SIZE = Math.ceil(POINT_COUNT / 10);
    const DOT_SIZE = 5;

    const rnd = makeRandom();
    const points = [];
    for (let i = 0; i < POINT_COUNT; ++i) {
      points.push([
        rnd.integer(-width / 2 + DOT_SIZE, width / 2 - DOT_SIZE),
        rnd.integer(-height / 2 + DOT_SIZE, height / 2 - DOT_SIZE),
      ]);
    }
    const delaunator = Triangulation.createTriangulation(points);
    const candidates = Triangulation.getEdgeConnections(delaunator, points);

    // Draw the dots (room centers)
    const gBackground = new Graphics();
    gBackground
      .beginFill(0xffffff, 1)
      .drawRect(-width / 2, -height / 2, width, height)
      .endFill()
      .beginFill(0xff0000, 1);
    for (let i = 0; i < points.length; ++i) {
      gBackground.drawCircle(points[i][0], points[i][1], DOT_SIZE);
    }
    gBackground.endFill();

    // Draw all of the "candidate" connections
    gBackground.lineStyle({ width: 1, color: 0xaf00af, alpha: 0.5 });
    candidates.forEach((x) => {
      const p1 = Point.fromArray(points[x.from]);
      const p2 = Point.fromArray(points[x.to]);
      gBackground.moveTo(p1.x, p1.y).lineTo(p2.x, p2.y);
    });
    gBackground.lineStyle();

    const gSolution = new PIXI.Graphics();
    this.root.addChild(gBackground);
    this.root.addChild(gSolution);

    const minspantree = new MinSpanKurskal(POINT_COUNT, candidates);
    // Solve all at once
    // while (!minspantree.isComplete) {
    //   minspantree.step();
    // }
    // minspantree.solution.forEach((next) => {
    //   const p1 = Point.fromArray(points[next.from]);
    //   const p2 = Point.fromArray(points[next.to]);
    //   gSolution
    //     .lineStyle({ color: 0, width: 3 })
    //     .moveTo(p1.x, p1.y)
    //     .lineTo(p2.x, p2.y)
    //     .lineStyle();
    // });

    const update = () => {
      // Solve iteratively
      const lines = [];
      for (let i = 0; i < BATCH_SIZE && !minspantree.isComplete; ++i) {
        const line = minspantree.step();
        if (line) {
          lines.push(line);
        }
      }
      lines.forEach((line) => {
        const p1 = Point.fromArray(points[line.from]);
        const p2 = Point.fromArray(points[line.to]);
        gSolution
          .lineStyle({ color: 0, width: 3 })
          .moveTo(p1.x, p1.y)
          .lineTo(p2.x, p2.y)
          .lineStyle();
      });
    };
    this.cleanup = subscribeToUpdates(app.ticker)(update);
  }
}
