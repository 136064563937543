export interface StringPair {
  key: string;
  value: string;
}

const encodeURIComponent = (x: string) => global.encodeURIComponent(x); //.replace(/\%2B/g, "+");

export const requireTrailing = (required: string) => (text: string) => {
  return text.endsWith(required) ? text : text + required;
};

export const requireTrailingSlash = requireTrailing("/");

export const hasParams = (url: string) => url.includes("?");

export const addFirstParam = (
  url: string,
  { key, value }: StringPair
): string => `${requireTrailingSlash(url)}?${key}=${encodeURIComponent(value)}`;

export const addAnotherParam = (
  url: string,
  { key, value }: StringPair
): string => `${url}&${key}=${encodeURIComponent(value)}`;

export const addParam = (url: string, pair: StringPair) =>
  !hasParams(url) ? addFirstParam(url, pair) : addAnotherParam(url, pair);

export const addParams = (url: string, params: StringPair[]) =>
  params.reduce((acc, cur) => addParam(acc, cur), url);
