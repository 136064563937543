import { IPoint, distanceSquared } from "./point";

interface TempNode<T> {
  distance: number;
  iUnreached: number;
  from: T;
  to: T;
}

export interface Node<T> {
  from: T;
  to: T;
}

export type Measurer<T> = (a: T, b: T) => number;

export const pointMeasurer = <T extends IPoint>(from: T, to: T) =>
  distanceSquared(from, to);

/***
 * Use Prim's algorithm
 * https://en.wikipedia.org/wiki/Prim%27s_algorithm
 */
export function createSpanTree<T>(items: T[], measure: Measurer<T>): Node<T>[] {
  const connections: Node<T>[] = [];
  const [pFirst, ...unreached] = items;
  const reached = [pFirst];

  while (unreached.length) {
    const candidates: TempNode<T>[] = [];

    for (const from of reached) {
      for (let i = 0; i < unreached.length; ++i) {
        const to = unreached[i];
        candidates.push({
          distance: measure(from, to),
          iUnreached: i,
          from,
          to,
        });
      }
    }

    const closest = candidates.reduce(
      (acc, cur) =>
        acc == undefined || cur.distance < acc.distance ? cur : acc,
      undefined
    );

    connections.push({ from: closest.from, to: closest.to });
    reached.push(closest.to);
    unreached.splice(closest.iUnreached, 1);
  }

  return connections;
}

export function initSpanTree<T>(items: T[], measure: Measurer<T>) {
  const connections: Node<T>[] = [];
  const [pFirst, ...unreached] = items;

  const reached = [pFirst];

  return {
    step() {
      if (unreached.length) {
        const candidates: TempNode<T>[] = [];

        for (const from of reached) {
          for (let i = 0; i < unreached.length; ++i) {
            const to = unreached[i];
            candidates.push({
              distance: measure(from, to),
              iUnreached: i,
              from,
              to,
            });
          }
        }

        const closest = candidates.reduce(
          (acc, cur) =>
            acc == undefined || cur.distance < acc.distance ? cur : acc,
          undefined
        );

        connections.push({ from: closest.from, to: closest.to });
        reached.push(closest.to);
        unreached.splice(closest.iUnreached, 1);
      }

      return {
        connections,
        reached,
        unreached,
        finished: unreached.length === 0,
      };
    },
  };
}
