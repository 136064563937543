import * as Vector from "./vector";

export interface IPoint extends Vector.Vector {}

/**
 * Create a point from x and y values.
 * @returns A new point
 */
export const create = (x: number, y: number): IPoint => ({ x, y });

/**
 * Create a new point with copies of the original x and y coordinates.
 * @returns A new vector
 */
export const clone: (p: IPoint) => IPoint = ({ x, y }) => ({ x, y });

export const fromArray = (p: [number, number]) => ({ x: p[0], y: p[1] });
/**
 * Add two points together.
 * @returns A new point
 */
export const add = (a: IPoint, b: IPoint): IPoint => Vector.add(a, b);

/**
 * Subtract point B from point A.
 * @returns A new point
 */
export const subtract = (a: IPoint, b: IPoint): IPoint => Vector.subtract(a, b);

/**
 * Multiply the point by the scalar.
 * @returns A new point
 */
export const multiply = (p: IPoint, scalar: number): IPoint =>
  Vector.multiply(p, scalar);

/**
 * Divide the point by the scalar.
 * @returns A new point
 */
export const divide = (p: IPoint, scalar: number): IPoint =>
  Vector.divide(p, scalar);

/**
 * Get the distance between two points.
 * @returns The distance.
 */
export function distance(a: IPoint, b: IPoint): number {
  const xd = b.x - a.x;
  const yd = b.y - a.y;
  return Math.sqrt(xd * xd + yd * yd);
}

/**
 * Get the distance between two points (avoiding the sqrt).
 * @returns The distance squared.
 */
export function distanceSquared(a: IPoint, b: IPoint): number {
  const xd = b.x - a.x;
  const yd = b.y - a.y;
  return xd * xd + yd * yd;
}
