import compassRosePath from "./images/compass-rose.png";
import stoneWallPath from "./images/stone-wall.jpg";
import pebblesPath from "./images/pebbles/*.jpg";
import wetRockPath from "./images/wet-rock-001/*.jpg";
import brickWallPath from "./images/bricks.jpg";
import woodSlatsPath from "./images/wood-slats.jpg";
import smallDoorPath from "./images/small-door.png";
import spiderPath from "./images/spider.png";

export const smallDoor = smallDoorPath;
export const woodSlats = woodSlatsPath;
export const compassRose = compassRosePath;
export const stoneWall = stoneWallPath;
export const brickWall = brickWallPath;
export const spider = spiderPath;

const buildTexture = (texturePath) => {
  const values = Object.values(texturePath);
  const find = (term) => values.find((x) => x.toLowerCase().includes(term));
  const temp = {
    base: find("base"),
    ambient: find("ambient"),
    normal: find("normal"),
    roughness: find("roughness"),
  };
  return temp;
};

export const pebbleTexture = buildTexture(pebblesPath);
export const wetRockTexture = buildTexture(wetRockPath);
