import { Line, Point } from ".";

export interface IAABB {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface hasX {
  x: number;
}
interface hasY {
  y: number;
}
interface hasWidth {
  width: number;
}
interface hasHeight {
  height: number;
}

export const pickX = <T extends hasX>(o: T) => o?.x;
export const pickY = <T extends hasY>(o: T) => o?.y;
export const pickWidth = <T extends hasWidth>(x: T) => x?.width;
export const pickHeight = <T extends hasHeight>(x: T) => x?.height;

export const right = (x: IAABB) => x.x + x.width;
export const bottom = (x: IAABB) => x.y + x.height;

export const fromPoints = (points: Point.IPoint[]) => {
  let xMin = Number.MAX_VALUE;
  let xMax = Number.MIN_VALUE;
  let yMin = Number.MAX_VALUE;
  let yMax = Number.MIN_VALUE;
  for (let i = 0; i < points.length; ++i) {
    const { x, y } = points[i];
    xMin = Math.min(xMin, x);
    xMax = Math.max(xMax, x);
    yMin = Math.min(yMin, y);
    yMax = Math.max(yMax, y);
  }
  return fromXYXY(xMin, yMin, xMax, yMax);
};

/**
 * Create an axis-aligned bounding box from two points (as scalars).
 * @returns A new box.
 */
export const fromXYXY = (
  xMin: number,
  yMin: number,
  xMax: number,
  yMax: number
): IAABB => {
  return {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
  };
};

/**
 * Create (bigger) bounding box from a group of other boxes.
 * @returns A new box big enough to enclose the boxes.
 */
export const fromBoxes = (boxes: IAABB[]): IAABB =>
  fromXYXY(
    Math.min(...boxes.map(pickX)),
    Math.min(...boxes.map(pickY)),
    Math.max(...boxes.map(right)),
    Math.max(...boxes.map(bottom))
  );

/**
 * Create an axis-aligned bounding box from scalar params.
 * @returns A new box.
 */
export const create = (
  x: number,
  y: number,
  width: number,
  height: number
): IAABB => ({
  x,
  y,
  width,
  height,
});

/**
 * Create a copy of the axis-aligned bounding box.
 * @returns A new box.
 */
export const clone = (source: IAABB): IAABB => ({
  x: source.x,
  y: source.y,
  width: source.width,
  height: source.height,
});

/**
 * Get the center of the box.
 */
export const center = (box: IAABB): Point.IPoint => ({
  x: box.x + box.width / 2,
  y: box.y + box.height / 2,
});

export const toPoints = (box: IAABB): Point.IPoint[] => [
  Point.create(box.x, box.y),
  Point.create(box.x + box.width, box.y + box.height),
];

/**
 * Resize the rectangle even from it's own center.
 */
export const scale = (box: IAABB, scalar: number) => {
  const width = box.width * scalar;
  const height = box.height * scalar;
  const xAdj = -(width - box.width) / 2;
  const yAdj = -(height - box.height) / 2;
  return create(box.x + xAdj, box.y + yAdj, width, height);
};

export const topFromAABB = (box: IAABB) =>
  Line.create(box.x, box.y, box.x + box.width, box.y);
export const bottomFromAABB = (box: IAABB) =>
  Line.create(box.x, box.y + box.height, box.x + box.width, box.y + box.height);
export const leftFromAABB = (box: IAABB) =>
  Line.create(box.x, box.y, box.x, box.y + box.height);
export const rightFromAABB = (box: IAABB) =>
  Line.create(box.x + box.width, box.y, box.x + box.width, box.y + box.height);

export function lineIntersectRectangle(box: IAABB, line: Line.ILine) {
  for (const edge of [
    topFromAABB,
    bottomFromAABB,
    leftFromAABB,
    rightFromAABB,
  ]) {
    const intersection = Line.intersection(line, edge(box));
    if (intersection) {
      return {
        edge: edge(box),
        intersection,
      };
    }
  }
}
