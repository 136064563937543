import { Point } from "../../GameMath";
import { makeRandom } from "../../utils/random";
import { connectRooms } from "./connectRooms";
import { DungeonMap, RenderCallack } from "./dungeon";
import { generateRooms } from "./roomGenerator";
import { DungeonGenerationOptions, getRenderDelayMs } from "./settings";

export const angleDistanceToPoint = (radians: number, distance: number) =>
  Point.create(Math.cos(radians) * distance, Math.sin(radians) * distance);

export async function generateMap(
  options: Partial<DungeonGenerationOptions> = {},
  seed?: number[],
  renderCallback?: RenderCallack
): Promise<DungeonMap> {
  const rnd = makeRandom(seed);

  console.log(`Placing ${options.RoomCount} Rooms`);
  const roomData = await generateRooms(rnd, options, renderCallback);
  console.log("Rooms Placed", {
    iterations: roomData.iterations,
    roomCount: roomData.rooms.length,
    collisions: roomData.collisions,
  });

  console.log("Connecting Rooms");
  const connections = await connectRooms(roomData.rooms, renderCallback);
  console.log("Rooms Connected");

  return {
    seed: rnd.seed,
    rooms: roomData.rooms,
    corridors: connections,
    debug: { collisions: roomData.collisions },
    random: rnd,
  };
}
