import * as PIXI from "pixi.js";
import * as NAV from "../DungeonGeneration/navtree";
import { Scene, subscribeToUpdates } from "./Scene";
import { Viewport } from "pixi-viewport";
import { Node } from "../DungeonGeneration/navtree";
import {
  stoneWall,
  brickWall,
  pebbleTexture,
  wetRockTexture,
  spider,
} from "../../assets/loader";
import { DungeonMap } from "../DungeonGeneration";
import { makeCorridor, makeNavPoints, makeRoom } from "./prefabs/makeRoom";
import { Vector } from "../../GameMath";
import { makePlayer } from "./prefabs/makePlayer";
import { drawVector } from "./helpers/drawVector";
import { getTextureAssets } from "./helpers/assets";
import { createUndugFloorSprite } from "./helpers/assetSprites";
import { Random } from "../../utils/random";

const UNITS_PER_METER = 1;
const VIEW_HEIGHT = 23;
const WALK_SPEED = 0.03;
const PLAYER_ROTATE_SPEED = 0.1;
const WORLD_WIDTH = 250;
const WORLD_HEIGHT = 250;
const PLAYER_SIZE = 1 * UNITS_PER_METER;
const PLAYER_THICKNESS = PLAYER_SIZE * 0.3;

class GameScene implements Scene {
  private _root: PIXI.Container;
  private readonly _onAssetsLoaded: any;
  private _loader: undefined | PIXI.Loader;
  private viewport: Viewport;
  private player: undefined | PIXI.Container;
  private curNav: undefined | NAV.Node;
  private curLink: undefined | NAV.RoomLink;
  private movementTarget: undefined | Vector.Vector;
  private cleanup: undefined | (() => unknown);

  public constructor(
    private app: PIXI.Application,
    private dungeon: DungeonMap,
    private navTree: Node[] = [],
    private random: Random
  ) {
    this._root = new PIXI.Container();

    this.viewport = new Viewport({
      screenWidth: this.app.screen.width,
      screenHeight: this.app.screen.height,
      worldWidth: WORLD_WIDTH,
      worldHeight: WORLD_HEIGHT,
      interaction: this.app.renderer.plugins.interaction,
      ticker: this.app.ticker,
    });

    this._onAssetsLoaded = this.onAssetsLoaded.bind(this);
  }

  public get root() {
    return this.viewport;
  }

  public activateScene() {
    this.viewport.addChild(this._root);

    this.cleanup = subscribeToUpdates(this.app.ticker)(this.update, this);
    if (this.navTree.length) {
      this.curNav = this.navTree[0];
      this.curLink = this.curNav.links[0];
      this.movementTarget = this.curLink.fromPoint;
    }

    if (!this._loader) {
      this.loadAssets();
    }

    this.viewport.drag().pinch().wheel().decelerate();
    // console.log({
    //   zoom: this.viewport.scale.x, // number of pixels per world unit
    //   pixelSize: 1 / this.viewport.scale.x, // world unit that is exactly 1 pixel
    // });
  }

  public deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._loader = undefined;
    this._root.removeChildren();
  }

  private _lastScale = 0;
  private _vector: PIXI.Graphics | undefined;

  private update(deltaFrame: number) {
    if (this._lastScale != this.viewport.scale.x) {
      this._lastScale = this.viewport.scale.x;
      if (this.player) {
        // this.root.removeChild(this.player);
        // this.player = this.makePlayer();
        // this.root.addChild(this.player);
        // this.viewport.follow(this.player);
      }
    }

    if (this.player && this.movementTarget && this.curLink) {
      if (
        Vector.magnitude(
          Vector.subtract(this.movementTarget, this.player.position)
        ) < WALK_SPEED
      ) {
        if (this.movementTarget == this.curLink.fromPoint) {
          this.movementTarget = this.curLink.toPoint;
        } else if (this.movementTarget == this.curLink.toPoint) {
          this.movementTarget = this.curLink.to.room.center;
        } else if (this.movementTarget === this.curLink.to.room.center) {
          const nextNav = this.curLink.to;
          const somewhereElse = nextNav.links.filter(
            (x) => x.to != this.curNav
          );
          this.curLink = somewhereElse.length
            ? somewhereElse[Math.floor(Math.random() * somewhereElse.length)]
            : nextNav.links[0];
          this.movementTarget = this.curLink.fromPoint;
          this.curNav = nextNav;
        }
      }

      const travelVector = Vector.subtract(
        this.movementTarget,
        this.player.position
      );

      const movement = Vector.multiply(
        Vector.normalize(travelVector),
        WALK_SPEED * deltaFrame
      );

      if (this._vector) {
        this._root.removeChild(this._vector);
      }
      this._vector = drawVector(
        Vector.scale(travelVector, this.viewport.scale.x),
        0xff0000
      );
      this._vector.scale.set(1 / this.viewport.scale.x);
      this._vector.position.set(this.player.position.x, this.player.position.y);
      this._root.addChild(this._vector);

      const newPosition = Vector.add(this.player.position, movement);
      this.player.position.set(newPosition.x, newPosition.y);

      // const playerDirection = Vector.fromAngle(this.player.rotation);
      // const newPlayerDirection = Vector.turnTowards(
      //   playerDirection,
      //   Vector.scale(travelVector, 100)
      // );
      // this.player.rotation = Vector.angle(newPlayerDirection);

      const travelRotation = Vector.angle(
        Vector.scale(Vector.negate(travelVector), 100)
      );
      this.player.rotation = travelRotation;
    }
  }

  private loadAssets() {
    console.log("Loading Assets");
    const assets = [
      stoneWall,
      brickWall,
      spider,
      ...getTextureAssets(pebbleTexture),
      ...getTextureAssets(wetRockTexture),
    ];

    this._loader = new PIXI.Loader();
    this._loader.add(assets);
    this._loader.load(this._onAssetsLoaded);
  }

  private onAssetsLoaded() {
    // console.log("Assets Loaded");
    const roomGeometry = this.dungeon.rooms.map((r) => {
      const g = makeRoom(this.app, r, this.random);
      g.position.set(r.center.x, r.center.y);
      return g;
    });
    const corridorGeometry = this.dungeon.corridors.map((x) => {
      const g = makeCorridor(x);
      return g;
    });
    const navigationGeometry = makeNavPoints(this.navTree);

    const backgroundGeometry = createUndugFloorSprite(
      WORLD_WIDTH,
      WORLD_HEIGHT,
      0.005
    );

    this.player = makePlayer(this.app);
    this.player.position.set(
      (this.curNav ? this.curNav.room : this.dungeon.rooms[0]).center.x,
      (this.curNav ? this.curNav.room : this.dungeon.rooms[0]).center.y
    );

    this._root.addChild(backgroundGeometry);
    roomGeometry.forEach((display) => {
      this._root.addChild(display);
    });
    corridorGeometry.forEach((x) => this._root.addChild(x));
    this._root.addChild(navigationGeometry);

    this.viewport.fit(true, VIEW_HEIGHT, VIEW_HEIGHT);
    this.viewport.ensureVisible(
      this.player.position.x - this.player.width,
      this.player.position.y - this.player.height,
      this.player.width,
      this.player.height
    );
    this._root.addChild(this.player);
    this.viewport.follow(this.player);
  }
}

export default GameScene;
