import { Line, Point } from "..";
import { safeArrayIndex } from "../../utils/safeArrayIndex";
import { Polygon, Vertex } from "./types";

export interface IntersectionCandidate {
  intersection: Vertex;
  segment: Line.ILine;
  vertex: Vertex;
  distance: number;
}

export function getAllIntersectingProjections(a: Polygon, b: Polygon) {
  let projections: Line.ILine[] = [];
  let segments: Line.ILine[] = [];
  let candidates: IntersectionCandidate[] = [];

  for (let i = 0; i < b.length; ++i) {
    const segment = Line.fromPoints(b[i], b[safeArrayIndex(b, i, 1)]);
    for (let j = 0; j < a.length; ++j) {
      const vertex = a[j];
      const perpendicular = Line.normal(segment, 1);
      const projection = Line.fromVector(
        perpendicular,
        vertex,
        Number.MAX_SAFE_INTEGER
      );
      const intersection = Line.intersection(segment, projection);
      if (intersection) {
        segments.push(segment);
        projections.push(projection);
        candidates.push({
          intersection,
          segment,
          vertex,
          distance: Point.distance(intersection, vertex),
        });
      }
    }
  }
  return {
    projections,
    segments,
    candidates,
  };
}
