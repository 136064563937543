import { Point } from "../../GameMath";
import { oppositeDirection } from "../../GameMath/vector/vector";
import { DungeonMap, Room } from "./dungeon";

export interface RoomLink {
  to: Node;
  distance: number;
  direction: string;
  fromPoint: Point.IPoint;
  toPoint: Point.IPoint;
}

export interface Node {
  room: Room;
  links: RoomLink[];
}

export function createNavigationTree(dungeon: DungeonMap) {
  const connections = [...dungeon.corridors];
  const nodes: Node[] = [];

  while (connections.length) {
    const current = connections[0];
    connections.splice(0, 1);

    const nodeFromRoom = (room: Room) => {
      const found = nodes.find((x) => x.room === room);
      if (found) {
        return found;
      } else {
        const node = { room, links: [] };
        nodes.push(node);
        return node;
      }
    };

    const fromNode = nodeFromRoom(current.from.room);
    const toNode = nodeFromRoom(current.to.room);
    const distance = Point.distance(fromNode.room.center, toNode.room.center);
    fromNode.links.push({
      to: toNode,
      distance,
      direction: current.direction!,
      fromPoint: { ...current.from.edge },
      toPoint: { ...current.to.edge },
    });
    toNode.links.push({
      to: fromNode,
      distance,
      direction: oppositeDirection(current.direction!),
      fromPoint: { ...current.to.edge },
      toPoint: { ...current.from.edge },
    });
  }

  return nodes;
}
