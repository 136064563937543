import * as PIXI from "pixi.js";
import { Line, Noise, Point, Polygon, Vector } from "../../../GameMath";
import { makeRandom } from "../../../utils/random";

export interface DeformOptions {
  borderSize: number; // The size of the room edge to deform.
  samplesPerUnit: number; // The number of noise samples per unit distance to use.
  smoothness: number; // The roughness of the wall lower is smoother, higher is rougher.
  octaves: number; // A whole number of noise layers/octaves. 1 is simple noise, >1 adds more layers of noise at 1:1 performance cost
}

export function createDeformOptions(
  polygon: Polygon.Polygon,
  borderSize = 0.05,
  resolution = 50,
  smoothness = 6,
  octaves = 1
): DeformOptions {
  const polySize = Point.distance(Polygon.centroid(polygon), polygon[0]) * 2;
  return {
    octaves,
    borderSize: polySize * borderSize,
    samplesPerUnit: resolution / polySize,
    smoothness: (1 / polySize) * smoothness,
  };
}

export function deformPolygon(
  polygon: Polygon.Polygon,
  options: Partial<DeformOptions> = {}
) {
  const borderSize = options?.borderSize ?? 1;
  const samplesPerUnit = options?.samplesPerUnit ?? 10;
  const smoothness = options?.smoothness ?? 0.05;
  const octaves = options?.octaves ?? 1;

  const rnd_seed = [2];
  const rnd = makeRandom(rnd_seed);
  const noise_seed = Noise.makeNoiseSeed2DHighQuality(rnd.engine);
  const simplex = Noise.makeNoise2D(noise_seed);
  const noise = (x: number, y: number) =>
    Noise.octave(simplex, x, y, smoothness, octaves);

  const rval: Polygon.Polygon = [];
  for (let i = 0; i < polygon.length; ++i) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % polygon.length];
    const segment = Line.fromPoints(p1, p2);
    // rval.push(p1);
    const normal = Vector.normalize(Line.normal(segment));
    const diff = Vector.subtract(p2, p1);
    const base = Vector.normalize(diff);
    const edgeLength = Vector.magnitude(diff);
    const sampleCount = Math.round(edgeLength * samplesPerUnit);
    let sample: number;
    for (let i = 0; i < sampleCount; ++i) {
      const p3 = Vector.add(
        p1,
        Vector.scale(base, (edgeLength / (sampleCount + 1)) * (i + 1))
      );
      sample = noise(p3.x, p3.y);
      const adj = sample * (borderSize / 2);
      let tweak = Vector.add(p3, Vector.scale(normal, adj));
      rval.push(tweak);
    }
    /**
     * We need to do something with the corners.
     * 1) Leaving them alone, can cause strange curling of the poly.
     * 2) Removing the original points isn't so bad if we have a lot of new points, but
     * it does cut off the corners and can cause odd straight cuts.
     * 3) We could push the corners in/out based on the samples around them, but that's tricky.
     **/
    // const p2Prev = Line.normal(segment);
    // const p2Next = Line.normal(
    //   Line.fromPoints(p2, polygon[(i + 2) % polygon.length])
    // );
    // const cornerNormal = Vector.normalize(Vector.add(p2Prev, p2Next));
    // const adj = sample * (borderSize / 2);
    // const cornerTweak = Vector.add(p2, Vector.scale(cornerNormal, adj));
    // console.log({ p2Prev, p2Next, cornerNormal, adj });
    // rval.push(cornerTweak);
  }
  return rval;
}

export function maskFromDeformation(
  polygon: Polygon.Polygon,
  displayScale: number
) {
  const mask = new PIXI.Graphics();
  mask
    .beginFill(0xffffff)
    .drawPolygon(polygon as PIXI.Point[])
    .endFill();
  mask.scale.set(displayScale);
  return mask;
}

export function shadowFromDeformation(
  polygon: Polygon.Polygon,
  displayScale: number,
  singlePixel: number
) {
  const edge = new PIXI.Graphics()
    .lineStyle({
      width: singlePixel * 24,
      alpha: 0.1,
      alignment: 0,
    })
    .drawPolygon(polygon as PIXI.Point[])
    .lineStyle({
      width: singlePixel * 12,
      alpha: 0.2,
      alignment: 0,
    })
    .drawPolygon(polygon as PIXI.Point[])
    .lineStyle({
      width: singlePixel * 6,
      alpha: 0.4,
      alignment: 0,
    })
    .drawPolygon(polygon as PIXI.Point[])
    .lineStyle({
      color: 0xffffff,
      width: singlePixel * 8,
      alpha: 0.1,
      alignment: 1,
    })
    .drawPolygon(polygon as PIXI.Point[])
    .lineStyle({
      width: singlePixel * 4,
      alpha: 0.8,
      alignment: 0,
    })
    .drawPolygon(polygon as PIXI.Point[]);
  edge.scale.set(displayScale);
  return edge;
}
