import { Polygon, Vertex } from "./types";

export function translate(polygon: Polygon, translation: Vertex) {
  const rval: Polygon = [];
  for (let i = 0; i < polygon.length; ++i) {
    const vertex = polygon[i];
    rval.push({ x: vertex.x + translation.x, y: vertex.y + translation.y });
  }
  return rval;
}
