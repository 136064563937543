import { rotate } from "./rotate";
import { translate } from "./translate";
import { Polygon, Vertex } from "./types";

export function rotateAndTranslate(
  polygon: Polygon,
  radians: number,
  movement: Vertex
) {
  return translate(rotate(polygon, radians), movement);
}
