export interface TextureDescription {
  base: string;
  ambient?: string;
  normal?: string;
  roughness?: string;
}

export function getTextureAssets(description: TextureDescription) {
  const rval: string[] = [];
  ["base", "ambient", "normal", "roughness"].forEach((property) => {
    if (property in description && description[property]) {
      rval.push(description[property]);
    }
  });
  return rval;
}
