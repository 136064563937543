import {
  hostedPages,
  getTokenUrl,
  getTokenFromRefreshUrl,
} from "./cognitoPaths";

export interface AuthTokens {
  expires_in: number;
  id_token: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
}

export interface JwtCommon {
  kid: string;
  alg: string;
  sub: string;
  token_use: string;
  exp: number;
  iat: number;
  iss: string;
  auth_time: number;
}

export interface IdToken extends JwtCommon {
  at_hash: string;
  aud: string;
  email_verified: boolean;
  preferred_username: string;
  email: string;
}

export interface AccessToken extends JwtCommon {
  scope: string;
  version: number;
  jti: string;
  client_id: string;
  username: string;
}

const decodePayload = (payload) => {
  const cleanedPayload = payload.replace(/-/g, "+").replace(/_/g, "/");
  const decodedPayload = atob(cleanedPayload);
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = ("00" + char.charCodeAt(0).toString(16)).slice(-2);
    return `${acc}%${uriEncodedChar}`;
  }, "");
  const jsonPayload = decodeURIComponent(uriEncodedPayload);

  return JSON.parse(jsonPayload);
};

export function decodeIdToken(idToken: string): IdToken | undefined {
  try {
    return decodePayload(idToken.split(".")[1]);
  } catch {}
}

export async function getUser(accessToken: string) {
  const opts: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await fetch(hostedPages.userInfo, opts);
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      throw new Error(json.error ?? "Authentication error");
    }
  } catch (error) {
    throw new Error(error?.message ?? "Authentication error");
  }
}

export async function getTokens(code: string) {
  const opts: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // Authorization: `Bearer ${code}`,
    },
  };
  try {
    const response = await fetch(getTokenUrl(code), opts);
    const json = await response.json();
    if (response.ok) {
      return json as AuthTokens;
    } else {
      throw new Error(json.error ?? "Authentication error");
    }
  } catch (error) {
    throw new Error(error?.message ?? "Authentication error");
  }
}

export async function refreshAccessToken(refreshToken: string) {
  const opts: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // Authorization: `Bearer ${code}`,
    },
  };
  try {
    const response = await fetch(getTokenFromRefreshUrl(refreshToken), opts);
    const json = await response.json();
    if (response.ok) {
      return json as AuthTokens;
    } else {
      throw new Error(json.error ?? "Authentication error");
    }
  } catch (error) {
    throw new Error(error?.message ?? "Authentication error");
  }
}

export class UserAuth {
  private tokens: AuthTokens;
  private user: any;

  public async inititalize(code: string) {
    try {
      this.tokens = await getTokens(code);
    } catch (error) {
      if (error?.message === "invalid_grant") {
        this.tokens = undefined;
        this.user = undefined;
      }
    }
  }
}
export function getLoginUrl(): string {
  throw new Error("Function not implemented.");
}

export function getSignUpUrl(): string {
  throw new Error("Function not implemented.");
}
