import { MainApp } from "./app/MainApp";

function initialize() {
  // const handleAuthChange = (isAuthenticated: boolean) => {
  //   console.log({ isAuthenticated });
  // };
  const root = document.getElementById("root");
  const gameApp = new MainApp(root);
}

initialize();
