import * as AABB from "../../GameMath/aabb";
import * as PIXI from "pixi.js";
import { Viewport } from "pixi-viewport";
import { Scene, subscribeToUpdates } from "./Scene";
import { getBoundingBoxFromPhysicsBody } from "../DungeonGeneration/roomGenerator";
import { createUndugFloorSprite } from "./helpers/assetSprites";
import { createPolygonRoom } from "./prefabs/polygonRoom";
import { getTextureAssets } from "./helpers/assets";
import { pebbleTexture, wetRockTexture } from "../../assets/loader";
import { withDragAndDrop } from "./helpers/dragAndDrop";
import { withRotation } from "./helpers/withRotation";
import { Line, Point, Vector } from "../../GameMath";
import { Connection, connectionDetails } from "./helpers/connectRoomsTogether";
import { drawLineSegment } from "./helpers/drawLineSegment";
import { safeArrayIndex } from "../../utils/safeArrayIndex";
import { drawVector } from "./helpers/drawVector";
import fitCurve from "fit-curve";

class ConnectionTestScene implements Scene {
  private readonly _root: PIXI.Container;
  // private readonly viewport: Viewport;
  private cleanup?: () => unknown;
  background: PIXI.Sprite | undefined;

  public constructor(private app: PIXI.Application) {
    this._root = new PIXI.Container();
    // this.viewport = new Viewport({
    //   screenWidth: this.app.screen.width,
    //   screenHeight: this.app.screen.height,
    //   worldWidth: 1000,
    //   worldHeight: 1000,
    //   interaction: this.app.renderer.plugins.interaction,
    //   ticker: this.app.ticker,
    // });

    // this.viewport.addChild(this._root);
  }

  public get root() {
    return this._root;
    // return this.viewport;
  }

  public activateScene() {
    this.app.loader.add([
      ...getTextureAssets(pebbleTexture),
      ...getTextureAssets(wetRockTexture),
    ]);
    this.app.loader.load(() => this.mainScene());
  }

  private mainScene() {
    // Center the camera at 0,0
    this._root.position.set(this.app.view.width / 2, this.app.view.height / 2);
    // set our object draw sizes to fit on the screen
    const unitToViewScale =
      Math.min(this.app.view.width, this.app.view.height) / 25;

    const rooms = [
      createPolygonRoom(this.app, 5, unitToViewScale, 1, 4),
      createPolygonRoom(this.app, 4, unitToViewScale, 1, 4),
      createPolygonRoom(this.app, 3, unitToViewScale, 1, 3),
      createPolygonRoom(this.app, 10, unitToViewScale, 1, 4),
    ];

    this.background = createUndugFloorSprite(
      this.app.view.width * 5,
      this.app.view.width * 10
    );
    const floorGroup = new PIXI.Container();
    floorGroup.addChild(this.background);
    this._root.addChild(floorGroup);

    const connections = rooms
      .filter((_, i) => i < rooms.length - 1)
      .map((room, i): Connection => {
        const nextRoom = rooms[safeArrayIndex(rooms, i, 1)];
        return { from: room, to: nextRoom };
      });

    rooms.forEach((room, i) => {
      floorGroup.addChild(room.sprite);
      withDragAndDrop(room.sprite, room.originalPolygon);
      withRotation(this.app, room.sprite, room.originalPolygon);
      room.sprite.buttonMode = true;

      // const hitBorder = new PIXI.Graphics()
      //   .lineStyle({ color: 0x0ff000, width: 2, alpha: 0.6 })
      //   .drawPolygon(room.originalPolygon as any);
      // room.sprite.addChild(hitBorder);
      room.sprite.position.set(
        -(this.app.view.width / 2) + 200 + (i % 3) * 375,
        -(this.app.view.height / 2) + 250 + Math.floor(i / 3) * 375
      );
    });

    // this.viewport.follow(room.sprite);
    // this.viewport.fit(true, room.sprite.width * 2, room.sprite.height * 2);
    const connectionGroup = new PIXI.Container();
    this._root.addChild(connectionGroup);

    let showDirectConnections = true;
    let showDirectShortConnections = false;
    let showShortestConnections = false;
    let showRaycastTesting = false;
    let showRealCorridors = true;
    let connectUsingSplines = false;

    const g = new PIXI.Graphics();
    connectionGroup.addChild(g);
    function update() {
      g.clear();
      connections.forEach((c) => {
        const connection = connectionDetails(c);
        const { from, to } = connection;

        // Draw direct connections
        if (showDirectConnections) {
          if (
            connectUsingSplines &&
            from.edge &&
            from.edgePoint &&
            to.edge &&
            to.edgePoint
          ) {
            let path: Point.IPoint[] = [];
            if (Point.distance(from.edgePoint, to.edgePoint) > 100) {
              const edgeNormal = Line.normal(from.edge);
              const edges = [
                Line.translate(
                  Line.fromVectorAtOrigin(Line.normal(from.edge), 20),
                  from.edgePoint.x,
                  from.edgePoint.y
                ),
                Line.translate(
                  Line.fromVectorAtOrigin(Line.normal(to.edge, 0), 20),
                  to.edgePoint.x,
                  to.edgePoint.y
                ),
              ];

              path.push(from.edgePoint, edges[0].p2, edges[1].p2, to.edgePoint);
            } else {
              path.push(from.edgePoint, to.edgePoint);
            }

            g.lineStyle(8, 0x7ff0).moveTo(path[0].x, path[0].y);
            const fit = fitCurve(
              path.map((p) => [p.x, p.y]),
              10
            );
            fit.forEach((c: any) =>
              g.bezierCurveTo(
                c[1][0],
                c[1][1],
                c[2][0],
                c[2][1],
                c[3][0],
                c[3][1]
              )
            );
            g.lineStyle();
          } else {
            drawLineSegment(
              Line.fromPoints(from.centroid, to.centroid),
              {
                color: 0x7ff0,
                width: 3,
              },
              g
            );
            drawVector(
              Vector.subtract(to.centroid, from.centroid),
              0x00ffff,
              30,
              from.centroid,
              g
            );
          }
        }

        // if (showDirectShortConnections && directConnection) {
        //   drawLineSegment(directConnection, { color: 0x0f0fff, width: 6 }, g);
        //   drawVector(
        //     Vector.subtract(directConnection.p2, directConnection.p1),
        //     0x00ffff,
        //     30,
        //     from.edgePoint,
        //     g
        //   );
        // }

        if (showRaycastTesting && connection.debug) {
          const { projections, segments, candidates } = connection.debug;
          if (projections) {
            projections.forEach((segment) =>
              drawLineSegment(
                segment,
                { color: 0xffffff, width: 2, alpha: 0.5 },
                g
              )
            );
          }

          if (segments) {
            segments.forEach((segment) =>
              drawLineSegment(
                segment,
                { color: 0x0ffff, width: 2, alpha: 0.5 },
                g
              )
            );
          }

          if (candidates) {
            candidates.forEach(({ intersection }) =>
              g
                .beginFill(0xffffff, 0.8)
                .drawCircle(intersection.x, intersection.y, 5)
                .endFill()
            );
          }
        }

        if (showShortestConnections && connection.shortest) {
          const shortest = connection.shortest;
          {
            drawLineSegment(shortest, { color: 0xff2f3f, width: 6 }, g);
            drawVector(
              Vector.subtract(shortest.p2, shortest.p1),
              0x00ffff,
              30,
              shortest.p1,
              g
            );
          }
        }

        if (showRealCorridors) {
          connection.corridors.forEach((connection) => {
            const leftWall = connection?.leftWall;
            const rightWall = connection?.rightWall;

            if (leftWall && rightWall) {
              g.beginFill(0xffffffff)
                .drawPolygon([
                  leftWall.p1 as PIXI.ObservablePoint,
                  leftWall.p2 as PIXI.ObservablePoint,
                  rightWall.p2 as PIXI.ObservablePoint,
                  rightWall.p1 as PIXI.ObservablePoint,
                ])
                .endFill();
            }

            if (leftWall) {
              drawLineSegment(
                leftWall,
                { color: 0xff0000, width: 3, alignment: 0 },
                g
              );
            }
            if (rightWall) {
              drawLineSegment(
                rightWall,
                { color: 0x00ff00, width: 3, alignment: 1 },
                g
              );
            }
          });
        }
      });
    }
    update();
    this.cleanup = subscribeToUpdates(this.app.ticker)(update, this);
  }

  public deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._root.removeChildren();
    this.background?.destroy();
    this.background = undefined;
  }

  // private async resetCamera(rooms: any) {
  //   // Zoom the camera to show the content
  //   if (this.viewport) {
  //     const extents = AABB.fromBoxes(
  //       rooms.map((x) => getBoundingBoxFromPhysicsBody(x))
  //     );
  //     this.viewport.fit(true, extents.width + 1, extents.height);
  //     this.viewport.ensureVisible(
  //       extents.x,
  //       extents.y,
  //       extents.width,
  //       extents.height
  //     );
  //   }
  // }
}

export default ConnectionTestScene;
