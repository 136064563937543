import { route } from "preact-router";
import { Fragment, h } from "preact";
import { useEffect, useReducer } from "preact/hooks";
import * as Auth from "./cognito";
import { getLoginUrl, getSignUpUrl } from "./cognitoPaths";

export interface WebProps {
  url?: string;
  matches?: any;
  code?: string;
}

export interface User {
  id: string;
  email: string;
  emailVerified: boolean;
  username: string;
}

const authToUser = (token: Auth.IdToken): User => ({
  id: token.sub,
  email: token.email,
  emailVerified: token.email_verified,
  username: token.preferred_username,
});

const LOCALSTORAGE_KEY_COGNITO_CODE = "cognito_refresh_token";
const saveCode = (code: string) =>
  window.localStorage.setItem(LOCALSTORAGE_KEY_COGNITO_CODE, code);
const loadCode = (): string =>
  window.localStorage.getItem(LOCALSTORAGE_KEY_COGNITO_CODE);
const clearCode = () =>
  window.localStorage.removeItem(LOCALSTORAGE_KEY_COGNITO_CODE);

interface AuthState {
  authCode?: string;
  refreshToken?: string;
  accessToken?: string;
  idToken?: string;
  user?: User;
}

type AuthAction =
  | { type: "accessTokenReceived"; tokens: Auth.AuthTokens }
  | { type: "refreshTokenFailed" }
  | { type: "loginComplete"; authCode: string };

const useAuthentication = () => {
  const [state, dispatch] = useReducer<AuthState, AuthAction>(
    (prev: AuthState, action: AuthAction) => {
      if (action.type === "accessTokenReceived") {
        const { tokens } = action;
        const user = tokens.id_token
          ? authToUser(Auth.decodeIdToken(tokens.id_token))
          : undefined;
        if (tokens.refresh_token) {
          console.log(
            "Saving refresh token",
            tokens.refresh_token.substring(0, 8)
          );
          saveCode(tokens.refresh_token);
        }
        return {
          ...prev,
          user,
          accessToken: action.tokens.access_token,
          idToken: action.tokens.id_token,
        };
      } else if (action.type === "refreshTokenFailed") {
        clearCode();
        return { user: prev.user };
      } else if (action.type === "loginComplete") {
        return { ...prev, authCode: action.authCode };
      } else {
        return prev;
      }
    },
    { refreshToken: loadCode() }
  );

  useEffect(() => {
    if (state.accessToken) return;

    const getTokensUsingRefreshToken = async (refreshToken: string) => {
      try {
        dispatch({
          type: "accessTokenReceived",
          tokens: await Auth.refreshAccessToken(refreshToken),
        });
      } catch (error) {
        console.error(error);
        dispatch({ type: "refreshTokenFailed" });
      }
    };

    const getTokenUsingAuthCode = async (authCode: string) => {
      try {
        dispatch({
          type: "accessTokenReceived",
          tokens: await Auth.getTokens(authCode),
        });
      } catch (error) {
        if (error?.message !== "invalid_grant") {
          console.error(error);
        }
        dispatch({ type: "refreshTokenFailed" });
      }
    };

    if (state.refreshToken) {
      // console.log("getTokensUsingRefreshToken");
      getTokensUsingRefreshToken(state.refreshToken);
    } else if (state.authCode) {
      // console.log("getTokenUsingAuthCode");
      getTokenUsingAuthCode(state.authCode);
    }
  }, [state]);

  return {
    ...state,
    handleSignIn(authCode: string) {
      console.log(authCode);
      if (state.authCode != authCode) {
        dispatch({ type: "loginComplete", authCode });
      }
    },
  };
};

export type AuthenticationCallback = (authenticated: boolean) => unknown;

export interface HomeProps extends WebProps {
  onAuthChanged?: AuthenticationCallback;
}

export const AuthHome = (props: HomeProps) => {
  const { authCode, accessToken, user, handleSignIn } = useAuthentication();

  // console.log({ authCode, accessToken, user, ...props });

  if (!authCode && props.code) {
    handleSignIn(props.code);
    route("/");
  }

  if (props.onAuthChanged) {
    // console.log("changed");
    props.onAuthChanged(accessToken != undefined);
  }

  const userControl =
    accessToken && user ? (
      <Fragment>
        {user.username}
        {/* <div>{user.email}</div>
      <div>{user.emailVerified ? "Email Verified" : "Email not verified"}</div> */}
      </Fragment>
    ) : undefined;

  const loginControl = accessToken ? undefined : (
    <div style="display: flex,">
      <a style="display:block" href={getLoginUrl()}>
        Login
      </a>
      <a style="display:block" href={getSignUpUrl()}>
        Create Account
      </a>
    </div>
  );

  const containerStyle: h.JSX.CSSProperties = {
    backgroundColor: "gray",
    padding: "0.5em",
    flex: 0,
    display: "flex",
    justifyContent: "space-between",
    minHeight: "2em",
  };

  const TitleStyle: h.JSX.CSSProperties = {
    fontFamily: "Arial",
    fontSize: "larger",
    fontWeight: "bold",
    color: "blue",
  };

  return (
    <div style={containerStyle}>
      <div style={TitleStyle}>Roguelike</div>
      {userControl}
      {loginControl}
    </div>
  );
};
