import * as PIXI from "pixi.js";

export type SubscriptionManager = (
  subscription: PIXI.TickerCallback<any>,
  context?: any
) => () => unknown;

export const subscribeToUpdates =
  (ticker: PIXI.Ticker): SubscriptionManager =>
  (subscription: PIXI.TickerCallback<any>, context?: any) => {
    ticker.add(subscription, context);
    return () => ticker.remove(subscription, context);
  };

export interface Scene {
  activateScene(): unknown;
  deactivateScene(): unknown;
  root: PIXI.Container;
}
