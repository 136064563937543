import * as PIXI from "pixi.js";
import { EventEmitter2 } from "eventemitter2";
import { Polygon, Vector } from "../../../GameMath";

export function withDragAndDrop(
  control: PIXI.Container,
  hitArea?: Polygon.Polygon
) {
  const parent = control.parent;
  const originalAlpha = control.alpha;
  let dragging = false;
  let data: PIXI.InteractionData | undefined;
  let dragPoint: Vector.Vector | undefined;

  if (hitArea) {
    control.hitArea = {
      contains: (x: number, y: number) => Polygon.contains(hitArea, { x, y }),
    };
  }

  function dragStart(event: PIXI.InteractionEvent) {
    data = event.data;
    control.alpha = 0.8;
    dragging = true;
    parent.removeChild(control);
    parent.addChild(control);
    const globalClickPoint: Vector.Vector = event.data.global;
    dragPoint = Vector.subtract(control.position, globalClickPoint);
  }

  function dragEnd(event: PIXI.InteractionEvent) {
    data = null;
    control.alpha = originalAlpha;
    dragging = false;
  }

  function dragMove(event: PIXI.InteractionEvent) {
    if (dragging) {
      const globalClickPoint: Vector.Vector = event.data.global;
      const position = Vector.add(dragPoint, globalClickPoint);
      control.position.set(position.x, position.y);
    }
  }

  control.interactive = true;
  const eventEmitter = control as unknown as EventEmitter2;
  eventEmitter.on("mousedown", dragStart);
  eventEmitter.on("touchstart", dragStart);
  eventEmitter.on("mouseup", dragEnd);
  eventEmitter.on("mouseupoutside", dragEnd);
  eventEmitter.on("touchend", dragEnd);
  eventEmitter.on("touchendoutside", dragEnd);
  eventEmitter.on("mousemove", dragMove);
  eventEmitter.on("touchmove", dragMove);
  const cleanup = () => eventEmitter.removeAllListeners();
}
