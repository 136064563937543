import * as PIXI from "pixi.js";

export interface ButtonOptions {
  fontSize: number;
  fontFamily: string;
  padding: number;
  backgroundColor: number;
  borderColor: number;
  borderColorHover: number;
}

export const createButton = (
  text: string,
  fn: () => unknown,
  options?: Partial<ButtonOptions>
) => {
  options = {
    fontSize: 16,
    fontFamily: "Arial",
    padding: 10,
    backgroundColor: 0xc0c0c0,
    borderColor: 0,
    borderColorHover: 0xffffff,
    ...options,
  };

  const root = new PIXI.Container();
  const textStyle: Partial<PIXI.ITextStyle> = {
    fontSize: options.fontSize,
    fontFamily: options.fontFamily,
  };
  const textControl = new PIXI.Text(text, textStyle);
  textControl.anchor.set(0.5);
  root.interactive = true;
  (root as any).on("mousedown", (event) => fn());

  const createBackground = (isMouseOver = false) => {
    const padding = options.padding;
    const borderColor = isMouseOver
      ? options.borderColorHover
      : options.borderColor;
    return new PIXI.Graphics()
      .beginFill(options.backgroundColor, 1)
      .lineStyle({ width: 2, color: borderColor })
      .drawRect(
        -(textControl.width / 2 + padding),
        -(textControl.height / 2 + padding),
        textControl.width + padding * 2,
        textControl.height + padding * 2
      )
      .lineStyle()
      .endFill();
  };

  (root as any).on("mouseover", (event) => {
    root.removeChild(background);
    background = createBackground(true);
    root.addChildAt(background, 0);
  });

  (root as any).on("mouseout", (event) => {
    root.removeChild(background);
    background = createBackground(false);
    root.addChildAt(background, 0);
  });

  let background = createBackground();
  root.addChild(background);
  root.addChild(textControl);
  return { root };
};
