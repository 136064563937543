/**
 * Get a safe array index, wrapping around as needed. This is guaranteed to wrap for
 * both overflow (positive) and underflow (negative) values.
 * @param array The array being indexed
 * @param index The current index into the array
 * @param delta The number of elements to increment or decrement
 * @returns
 */
export function safeArrayIndex(
  array: ArrayLike<unknown>,
  index: number,
  delta = 1
) {
  let j = index + delta;
  const length = array.length;

  // Javascript's "% operator" is "remainder" not "modulo".
  // It might not behave how you think for negative values.
  return ((j % length) + length) % length;
}
