import { UnionFind } from "./UnionFind";

export interface MinSpanData {
  from: number;
  to: number;
  distance: number;
}

// Create a Minimum Spanning Tree using Kurskal's algorithm
// https://www.youtube.com/watch?v=lU3o3tuI6FE&list=PLQfaHkBRINsyT705454DHyAOSV31HFsTh&index=6
export class MinSpanKurskal {
  private readonly _solution: MinSpanData[] = [];
  private readonly union: UnionFind;
  private iNext = 0;

  public constructor(pointCount: number, private candidates: MinSpanData[]) {
    this.union = new UnionFind(pointCount);
  }

  public get isComplete() {
    return this.iNext === this.candidates.length;
  }

  public get solution() {
    return this._solution;
  }

  public solve() {
    while (!this.isComplete) {
      this.step();
    }
    return this.solution;
  }

  public step() {
    if (this.iNext === this.candidates.length) {
      return; // Done
    }

    const candidate = this.candidates[this.iNext++];
    let p1Set = this.union.findSet(candidate.from);
    let p2Set = this.union.findSet(candidate.to);

    // Case 1: Neither point are in the solution set, so create a new set
    if (p1Set === -1 && p2Set === -1) {
      this.union.makeSet(candidate.from, candidate.to);
      this._solution.push(candidate);
      return candidate;
    }

    // Case 2: Never add connections between points in the same set
    else if (p1Set === p2Set) {
      return;
    }

    // Case 3: The points are in different sets, so join the sets
    else {
      if (p1Set === -1) {
        this.union.union(p2Set, candidate.from);
      } else if (p2Set === -1) {
        this.union.union(p1Set, candidate.to);
      } else {
        this.union.union(p1Set, p2Set);
      }
      this._solution.push(candidate);
      return candidate;
    }
  }
}
