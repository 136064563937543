import * as PIXI from "pixi.js";
import { Polygon, Point, Line, Vector } from "../../../GameMath";
import { PolygonRoom } from "../prefabs/polygonRoom";

/**
 * Get everything we know about how two rooms connect to to each other.
 * @param polygon The raw (0,0) polygon of the source room
 * @param pixiDisplayObject The PXI display object associated with the source room
 * @param sourcePoint The center point of the source room
 * @param targetPoint  The center point of the target room
 * @returns
 */
export function connectionData(
  polygon: Polygon.Polygon,
  pixiDisplayObject: PIXI.DisplayObject,
  sourcePoint: Point.IPoint,
  targetPoint: Point.IPoint
) {
  const centroid = pixiDisplayObject.position;
  const rotation = pixiDisplayObject.rotation;
  const poly = Polygon.rotateAndTranslate(
    polygon,
    pixiDisplayObject.rotation,
    centroid
  );
  const intersection = Line.intersectsPolygon(
    Line.fromPoints(sourcePoint, targetPoint),
    poly
  );

  return {
    polygon: poly,
    centroid,
    rotation,
    edge: intersection?.edge,
    edgePoint: intersection?.intersection,
  };
}

export type ConnectionData = ReturnType<typeof connectionData>;

function getIntersectionFromMidpoint(
  midpoint: Point.IPoint,
  normal: Vector.Vector,
  from: Polygon.Polygon,
  to: Polygon.Polygon
) {
  const fromTest = Line.fromVector(Vector.negate(normal), midpoint, 20000);
  const fromIntersection = Line.intersectsPolygon(fromTest, from);
  const toTest = Line.fromVector(normal, midpoint, 20000);
  const toIntersection = Line.intersectsPolygon(toTest, to);
  return fromIntersection?.intersection !== undefined &&
    toIntersection?.intersection !== undefined
    ? Line.fromPoints(
        fromIntersection.intersection,
        toIntersection.intersection
      )
    : undefined;
}

function getCorridorWalls(
  fromPolygon: Polygon.Polygon,
  toPolygon: Polygon.Polygon,
  connection: Line.ILine,
  corridorWidth: number
) {
  const perpendicularDirection = Vector.normalize(Line.normal(connection));
  const lineDirection = Vector.normalize(
    Vector.subtract(connection.p2, connection.p1)
  );
  const midPoint = Line.midpoint(connection);

  const wallCandidates: Line.ILine[] = [];
  for (let i = 0; i < 11; i++) {
    const candidatePoint = Point.add(
      midPoint,
      Vector.scale(perpendicularDirection, (corridorWidth / 2) * (i - 5))
    );

    const candidate = getIntersectionFromMidpoint(
      candidatePoint,
      lineDirection,
      fromPolygon,
      toPolygon
    );
    if (candidate !== undefined) {
      wallCandidates.push(candidate);
    }
  }

  let leftWall: Line.ILine | undefined;
  let rightWall: Line.ILine | undefined;

  if (wallCandidates.length >= 3) {
    // Just get any two walls that work
    // We cast every half-width, so we need to skip 1 to get the right width.
    // leftWall = wallCandidates[2];
    // rightWall = wallCandidates[0];
    const lengthLimit = Line.length(connection) * 1.15;

    // Find the best two walls that are closest to the same length
    let difference = Number.MAX_VALUE;
    for (let i = 0; i < wallCandidates.length - 2; ++i) {
      const rightLength = Line.length(wallCandidates[i]);
      const leftLength = Line.length(wallCandidates[i + 2]);
      // const diff = Math.abs(leftLength - rightLength);
      const diff =
        Math.max(leftLength, rightLength) / Math.min(leftLength, rightLength);
      if (
        diff < difference
        //  &&leftLength <= lengthLimit &&
        // rightLength <= lengthLimit
      ) {
        difference = diff;
        rightWall = wallCandidates[i];
        leftWall = wallCandidates[i + 2];
      }
    }
  }

  return { leftWall, rightWall };
}

function createCorridor(
  fromData: ConnectionData,
  toData: ConnectionData,
  connection: Line.ILine | undefined,
  corridorWidth = 25
) {
  if (
    !connection ||
    connection?.p1 === undefined ||
    connection?.p2 === undefined
  ) {
    return undefined;
  }

  let walls = getCorridorWalls(
    fromData.polygon,
    toData.polygon,
    connection,
    corridorWidth
  );

  return { ...walls };
}

export interface Connection {
  from: PolygonRoom;
  to: PolygonRoom;
}

function getCenterOnCenterConnection(
  from: ConnectionData,
  to: ConnectionData
): Line.ILine | undefined {
  return from.edgePoint && to.edgePoint
    ? Line.fromPoints(from.edgePoint, to.edgePoint)
    : undefined;
}

function getClosestConnection(from: ConnectionData, to: ConnectionData) {
  return from.polygon && to.polygon
    ? Polygon.getClosestConnectionBetweenPolygons(from.polygon, to.polygon)
    : undefined;
}

export function connectionDetails({ from, to }: Connection) {
  const fromData = connectionData(
    from.originalPolygon,
    from.sprite,
    from.sprite.position,
    to.sprite.position
  );

  const toData = connectionData(
    to.originalPolygon,
    to.sprite,
    to.sprite.position,
    from.sprite.position
  );

  const centerConnection = getCenterOnCenterConnection(fromData, toData);
  const closestConnectionData = getClosestConnection(fromData, toData);
  const closestConnection: Line.ILine | undefined =
    closestConnectionData?.connection;

  const centerCorridor = createCorridor(fromData, toData, centerConnection);
  const closestCorridor = createCorridor(fromData, toData, closestConnection);

  const rval = {
    from: fromData,
    to: toData,
    shortest: closestConnection,
    debug: { ...closestConnectionData },
    corridors: [/*centerCorridor,*/ closestCorridor],
  };

  return rval;
}

export type ConnectionDetails = ReturnType<typeof connectionDetails>;
