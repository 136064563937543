import { Router } from "preact-router";
import { h, render } from "preact";
import { AuthenticationCallback, AuthHome } from "./auth/AuthHome";

const NavShim = (props: any) => (
  <Router>
    <AuthHome {...props} path="/" />
  </Router>
);

export const bootstrapWeb = (
  root: HTMLElement,
  handleAuthChange?: AuthenticationCallback
) => {
  render(<NavShim onAuthChanged={handleAuthChange} />, root);
};
