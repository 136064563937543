import { Corridor, RenderCallack, Room } from "./dungeon";
import { HALL_WIDTH } from "./settings";
import { Delay } from "../../utils/delay";
import {
  Triangulation,
  Line,
  Point,
  Vector,
  MinSpanKurskal,
} from "../../GameMath";

const animationDelay = () => Delay(100);

export async function connectRooms(
  rooms: Room[],
  renderCallback?: RenderCallack
): Promise<Corridor[]> {
  const points: [number, number][] = [];
  for (let i = 0; i < rooms.length; ++i) {
    points.push([rooms[i].center.x, rooms[i].center.y]);
  }
  const delaunator = Triangulation.createTriangulation(points);
  const candidates = Triangulation.getEdgeConnections(delaunator, points);
  const minspantree = new MinSpanKurskal(rooms.length, candidates);

  // Solve all at once
  const solution = minspantree.solve();
  const corridors = solution.map((link) => {
    const p1 = rooms.find(
      (r) =>
        r.center.x === points[link.from][0] &&
        r.center.y === points[link.from][1]
    );
    const p2 = rooms.find(
      (r) =>
        r.center.x === points[link.to][0] && r.center.y === points[link.to][1]
    );

    if (p1 === undefined || p2 === undefined) {
      console.log({ p1, p2 });
      throw new Error("Can find expected rooms.");
    }

    return {
      width: 1,
      from: { room: p1, edge: p1.center },
      to: { room: p2, edge: p2.center },
      direction: "",
    };
  });

  if (renderCallback) {
    const MAX_FRAMES = 30;
    const BATCH_SIZE = Math.ceil(corridors.length / MAX_FRAMES);
    for (let i = 1; i <= MAX_FRAMES; ++i) {
      const chunk = corridors.slice(
        0,
        Math.min(i * BATCH_SIZE, corridors.length)
      );
      renderCallback({ rooms, corridors: chunk });
      await animationDelay();
    }
  }

  /**
   * We now have connections from the center of one room to another.
   * Here, we trim each line segment to include only the part between each room (not inside them).
   */
  const originalCount = corridors.length;
  for (let i = 0; i < originalCount; i++) {
    const corridor = corridors[i];
    const { from, to } = corridor;

    // Determine which edge of each room is crossed by the center-to-center connection.
    const fromPoint = Line.intersectsPolygon(
      Line.fromPoints(from.room.center, to.room.center),
      from.room.vertices
    );

    const toPoint = Line.intersectsPolygon(
      Line.fromPoints(to.room.center, from.room.center),
      to.room.vertices
    );

    if (!fromPoint || !toPoint) {
      throw new Error(
        "We are are drawing a line through a known room location. We require " +
          "it to actually collide with the perimeter. If there is not a collision, something is broken."
      );
    }

    from.edge = fromPoint.intersection;
    to.edge = toPoint.intersection;

    const quadrant = Vector.direction(Line.normal(fromPoint.edge));
    const horizontal = quadrant === "Left" || quadrant === "Right";
    const mainPicker = horizontal
      ? (p: Point.IPoint) => p.x
      : (p: Point.IPoint) => p.y;
    const crossPicker = horizontal
      ? (p: Point.IPoint) => p.y
      : (p: Point.IPoint) => p.x;
    const distanceBetweenRooms = mainPicker(
      Point.subtract(toPoint.edge.p1, fromPoint.edge.p1)
    );

    const top = Math.max(
      Math.min(crossPicker(fromPoint.edge.p1), crossPicker(fromPoint.edge.p2)),
      Math.min(crossPicker(toPoint.edge.p1), crossPicker(toPoint.edge.p2))
    );
    const bottom = Math.min(
      Math.max(crossPicker(fromPoint.edge.p1), crossPicker(fromPoint.edge.p2)),
      Math.max(crossPicker(toPoint.edge.p1), crossPicker(toPoint.edge.p2))
    );

    const distanceBetweenLines = bottom - top;

    if (distanceBetweenLines >= HALL_WIDTH) {
      // We can draw a direct connection between the two rooms
      let centerLine: Line.ILine;
      if (horizontal) {
        const topLine = Line.create(
          fromPoint.edge.p1.x,
          top,
          fromPoint.edge.p1.x + distanceBetweenRooms,
          top
        );
        centerLine = Line.translate(topLine, 0, distanceBetweenLines / 2);
      } else {
        const leftLine = Line.create(
          top,
          fromPoint.edge.p1.y,

          top,
          fromPoint.edge.p1.y + distanceBetweenRooms
        );
        centerLine = Line.translate(leftLine, distanceBetweenLines / 2, 0);
      }
      corridor.from.edge = centerLine.p1;
      corridor.to.edge = centerLine.p2;
      corridor.width = HALL_WIDTH;
      corridor.direction = quadrant;
    } else {
      corridor.direction = "diagonal";
      // The rooms are at a diagonal, so we can't directly connect them
      // corridor.debug = { points: [], lines: [] };
      // console.log({ distanceBetweenLines, top, bottom });
      // const corner = Point.create(from.room.center.x, to.room.center.y);
      // corridor.debug.points.push(corner);
      // const leg1 = Line.fromPoints(from.room.center, corner);
      // const leg2 = Line.fromPoints(corner, to.room.center);
      // corridor.debug.lines.push(leg1, leg2);
    }
  }
  return corridors;
}
