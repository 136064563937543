import { Polygon } from "./types";

export function getClosestVerticesBetweenPolygons(
  polyA: Polygon,
  polyB: Polygon
) {
  let shortestDistance = Number.MAX_VALUE;
  let iPolyAClosest = -1;
  let iPolyBClosest = -1;
  for (let i = 0; i < polyA.length; ++i) {
    const a = polyA[i];
    for (let j = 0; j < polyB.length; ++j) {
      const b = polyB[j];
      const xd = b.x - a.x;
      const yd = b.y - a.y;
      const distance = xd * xd + yd * yd;
      if (distance < shortestDistance) {
        shortestDistance = distance;
        iPolyAClosest = i;
        iPolyBClosest = j;
      }
    }
  }

  return {
    iPolyAClosest,
    iPolyBClosest,
    distance: Math.sqrt(shortestDistance),
  };
}
