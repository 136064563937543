import * as PIXI from "pixi.js";
import { EventEmitter2 } from "eventemitter2";
import { Polygon, Vector } from "../../../GameMath";

export interface WheelEvent {
  mousePosition: PIXI.Point;
  wheelDelta: number;
}

let callbacks: ((e: WheelEvent) => unknown)[] | undefined;
let wheelCleanup: (() => unknown) | undefined;
function registerWheelEvents(
  app: PIXI.Application,
  callback?: (event: WheelEvent) => unknown
) {
  if (callbacks === undefined) {
    callbacks = [];
    const mousePosition = new PIXI.Point();

    const onWheel = (event) => {
      // app.renderer.plugins.interaction.mapPositionToPoint(
      //   mousePosition,
      //   event.wheelDelta,
      //   event.x,
      //   event.y
      // );
      const mousePosition = {
        x: event.offsetX,
        y: event.offsetY,
      } as PIXI.Point;
      const payload = { mousePosition, wheelDelta: Number(event.wheelDelta) };
      for (let i = 0; i < callbacks.length; ++i) {
        try {
          callbacks[i](payload);
        } catch {}
      }
    };

    app.view.addEventListener("mousewheel", onWheel);
    wheelCleanup = () => app.view.removeEventListener("mousewheel", onWheel);
    console.log("Registered wheel event listener");
  }

  if (callback) {
    callbacks.push(callback);
  }
}

function unregisterWheelEvent(callback: (e: WheelEvent) => unknown) {
  if (callbacks !== undefined) {
    for (let i = 0; i < callbacks.length; ++i) {
      if (callbacks[i] === callback) {
        callbacks.splice(i, 1);
      }
    }
  }

  if (callbacks.length === 0) {
    wheelCleanup?.();
    wheelCleanup = undefined;
    callbacks = undefined;
    console.log("Unregistered wheel event listener");
  }
}

export function withRotation(
  app: PIXI.Application,
  control: PIXI.Container,
  hitArea?: Polygon.Polygon
) {
  const parent = control.parent;
  const originalAlpha = control.alpha;
  let data: PIXI.InteractionData | undefined;

  if (hitArea) {
    control.hitArea = {
      contains: (x: number, y: number) => Polygon.contains(hitArea, { x, y }),
    };
  }

  function onWheel(event: WheelEvent) {
    if (
      app.renderer.plugins.interaction.hitTest(event.mousePosition) === control
    ) {
      control.angle += 2 * Math.sign(event.wheelDelta);
    }
  }

  registerWheelEvents(app, onWheel);

  control.interactive = true;
  const cleanup = () => unregisterWheelEvent(onWheel);
}
