import { Polygon, Vertex } from "./types";

export function scale(polygon: Polygon, scale: number) {
  const rval: Polygon = [];
  for (let i = 0; i < polygon.length; ++i) {
    const vertex = polygon[i];
    rval.push({ x: vertex.x * scale, y: vertex.y * scale });
  }
  return rval;
}
