// import { io, Socket } from "socket.io-client";
import * as PIXI from "pixi.js";
import { bootstrapWeb } from "../Main";
import ResizeTestScene from "./Pixi/ResizeTestScene";
import GameScene from "./Pixi/GameScene";
import DungeonDesignerScene from "./Pixi/DungeonDesignerScene";
import { generateMap } from "./DungeonGeneration";
import { createNavigationTree } from "./DungeonGeneration/navtree";
import ConnectionTestScene from "./Pixi/ConnectionTestScene";
import {
  DefaultDungeonGenerationOptions,
  DungeonGenerationOptions,
} from "./DungeonGeneration/settings";
import PlaygroundScene from "./Pixi/PlaygroundScene";
import { createPixiApp, SceneManager } from "./Pixi/PixiApp";

const LOCALSTORAGE_KEY_SEED = "seed";
const saveSeed = (seed: number[]) =>
  localStorage.setItem(LOCALSTORAGE_KEY_SEED, JSON.stringify(seed));
export const loadSeed = (): number[] => {
  try {
    const seed = localStorage.getItem(LOCALSTORAGE_KEY_SEED);
    if (!seed) {
      return [];
    }
    return JSON.parse(seed);
  } catch {
    return [];
  }
};

/**
import sysend from "sysend";
sysend.on("New Movement Target", (payload: any, eventName: string) => {
  console.log({ payload, eventName });
});

sysend.broadcast("New Movement Target", {
  target: this.movementTarget,
  sender: this.playerName,
});
 */

export class MainApp {
  private readonly pixiApp: PIXI.Application;
  private readonly sceneManager: SceneManager;

  constructor(_parent: HTMLElement) {
    let parent = document.body;
    bootstrapWeb(parent, this.handleAuthChange);
    this.pixiApp = createPixiApp(parent);
    const app = this.pixiApp;

    this.sceneManager = new SceneManager(app);

    const path = window?.location?.pathname?.toLowerCase() ?? "";
    switch (path) {
      case "/map": {
        const urlParams = new URLSearchParams(
          (window?.location?.search ?? "").toLowerCase()
        );
        const roomCount = Number.parseInt(urlParams.get("roomcount"), 10) || 20;
        this.generateMapOnly(roomCount);
        break;
      }
      case "/playground": {
        this.sceneManager.setScene(new PlaygroundScene(app));
        break;
      }
      case "/connections": {
        this.sceneManager.setScene(new ConnectionTestScene(app));
        break;
      }
      case "/size": {
        this.sceneManager.setScene(new ResizeTestScene(app));
        break;
      }
      default: {
        this.generateRandomMap().then((dungeon) => {
          const navTree = createNavigationTree(dungeon);
          this.sceneManager.setScene(
            new GameScene(app, dungeon, navTree, dungeon.random)
          );
        });
      }
    }
  }

  private async generateMapOnly(RoomCount: number) {
    const scene = new DungeonDesignerScene(this.pixiApp);
    this.sceneManager.setScene(scene);
    const mapOptions: DungeonGenerationOptions = {
      ...DefaultDungeonGenerationOptions,
      RoomCount,
    };
    const dungeon = await generateMap(mapOptions, [], (x) => scene.drawMap(x));
    scene.drawMap(dungeon);
  }

  private async generateRandomMap(showProgress = false) {
    const scene = new DungeonDesignerScene(this.pixiApp);
    this.sceneManager.setScene(scene);
    const seed: number[] = []; //loadSeed();
    const mapOptions: DungeonGenerationOptions = {
      ...DefaultDungeonGenerationOptions,
      RoomCount: 15,
    };
    const dungeon = await generateMap(
      mapOptions,
      seed,
      showProgress
        ? (x) => {
            scene.drawMap(x);
          }
        : undefined
    );
    saveSeed(dungeon.seed);
    if (showProgress) {
      scene.drawMap(dungeon);
    }
    return dungeon;
  }

  private handleAuthChange = (isAuthenticated: boolean) => {};
}
