import * as PIXI from "pixi.js";
import { Vector } from "../../../GameMath";
import { IPoint } from "../../../GameMath/point";
import { Scene } from "../Scene";

export class WindowControl implements Scene {
  private _root: PIXI.Container;

  public constructor(
    private app: PIXI.Application,
    private child: Scene,
    private titleText = "Window Title"
  ) {}

  public get root() {
    return this._root;
  }

  public activateScene() {
    this._root = new PIXI.Container();

    const windowPadding = 5;
    const childBounds = this.child.root.getBounds();
    const windowWidth = (childBounds.width || 400) + windowPadding * 2;
    const windowHeight = (childBounds.height || 400) + windowPadding * 2;

    const text = new PIXI.Text(this.titleText, {
      fontFamily: "Arial",
      fontSize: 14,
      fill: "white",
    });

    const titlePadding = 6;
    text.position.set(titlePadding, titlePadding);
    const titleHeight = text.height + titlePadding * 2;
    const windowTitle = new PIXI.Graphics()
      .beginFill(0x0000a0, 1)
      .drawRect(0, 0, windowWidth, titleHeight)
      .endFill();

    let isToggled = true;
    let toggle: PIXI.Graphics;
    const updateToggle = () => {
      if (toggle) {
        this._root.removeChild(toggle);
      }
      const toggleColor = isToggled ? 0x009000 : 0x900000;
      toggle = new PIXI.Graphics()
        .beginFill(toggleColor, 1)
        .drawCircle(0, 0, (titleHeight - titlePadding * 2) / 2)
        // .drawRect(
        //   -(titleHeight - titlePadding * 2) / 2,
        //   -(titleHeight - titlePadding * 2) / 2,
        //   titleHeight - titlePadding * 2,
        //   titleHeight - titlePadding * 2
        // )
        .endFill();
      toggle.position.set(
        windowTitle.x + windowTitle.width - titlePadding * 2,
        windowTitle.y + windowTitle.height / 2
      );
      this._root.addChild(toggle);
    };

    let frame: PIXI.Graphics;
    const updateFrame = () => {
      if (frame) {
        this._root.removeChild(frame);
      }

      frame = new PIXI.Graphics()
        .beginFill(0xffffff, 0.8)
        .drawRect(
          0,
          titleHeight,
          windowWidth,
          isToggled ? windowHeight : windowPadding * 2 * 0
        )
        .endFill();

      this._root.addChildAt(frame, 0);
    };

    updateFrame();
    this._root.addChild(windowTitle);
    this._root.addChild(text);
    updateToggle();
    this.child.root.position.set(windowPadding);
    this._root.addChild(this.child.root);

    this._root.interactive = true;

    this.child.root.pivot.set(
      -windowWidth / 2,
      -windowHeight / 2 - titleHeight
    );
    this.child.root.position.set(0, 0);

    const moveToTop = (control: PIXI.Container) => {
      const parent = control.parent;
      parent.removeChild(control);
      parent.addChild(control);
    };

    let dragging = false;
    let dragPoint: IPoint;
    (this._root as any).on("mousedown", (event) => {
      const globalClickPoint: IPoint = event.data.global;
      // const localClickPoint = this.root.toLocal(globalClickPoint);
      if (toggle.containsPoint(globalClickPoint)) {
      } else if (windowTitle.containsPoint(globalClickPoint) && !dragging) {
        dragging = true;
        const globalClickPoint: IPoint = event.data.global;
        dragPoint = Vector.subtract(this._root.position, globalClickPoint);
        moveToTop(this._root);
      }

      (this._root as any).on("mousemove", (event) => {
        if (dragging) {
          if (event.data.buttons === 0 && dragPoint) {
            dragging = false;
            dragPoint = undefined;
          } else {
            const globalClickPoint: IPoint = event.data.global;
            const newPoint = Vector.add(globalClickPoint, dragPoint);
            this._root.position.set(newPoint.x, newPoint.y);
          }
        }
      });

      (this._root as any).on("mouseup", (event) => {
        if (toggle.containsPoint(globalClickPoint)) {
          isToggled = !isToggled;
          if (isToggled) {
            this.child.activateScene();
            // this.root.addChild(this.child.root);
          } else {
            // this.root.removeChild(this.child.root);
            this.child.deactivateScene();
          }
          updateFrame();
          updateToggle();
        }

        if (dragging) {
          const globalClickPoint: IPoint = event.data.global;
          const newPoint = Vector.add(globalClickPoint, dragPoint);
          this._root.position.set(newPoint.x, newPoint.y);
          dragging = false;
          dragPoint = undefined;
        }
      });
    });

    return this._root;
  }

  public deactivateScene() {
    this.child?.deactivateScene();
    if (this.root) {
      (this.root as any).removeAllListeners();
      this._root.removeChildren();
      this._root = undefined;
    }
  }
}
