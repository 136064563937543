import { Scene, subscribeToUpdates } from "./Scene";
import * as PIXI from "pixi.js";

class ResizeTestScene implements Scene {
  private readonly _root: PIXI.Container;
  private cleanup: undefined | (() => void);
  private width: number;
  private height: number;

  public constructor(private app: PIXI.Application) {
    this._root = new PIXI.Container();
    this.width = this.app.view.width;
    this.height = this.app.view.height;
  }

  public get root() {
    return this._root;
  }

  public activateScene() {
    this.createAssets();
    this.cleanup = subscribeToUpdates(this.app.ticker)(this.update, this);
  }

  public deactivateScene() {
    this.cleanup?.();
    this.cleanup = undefined;
    this._root?.removeChildren();
  }

  private update() {
    if (
      this.app.view.width != this.width ||
      this.app.view.height != this.height
    ) {
      console.log(window.innerHeight);
      this.width = this.app.view.width;
      this.height = this.app.view.height;
      this._root.removeChildren();
      this.createAssets();
    }
  }

  private labeledRectangle(
    x: number,
    y: number,
    width: number,
    height: number,
    color: number,
    text?: string
  ) {
    const box = new PIXI.Graphics()
      .beginFill(color)
      .drawRect(x, y, width, height)
      .endFill();

    const fontSize = Math.min(width, height) / 4;
    const labelText = text ?? `${x},${y}\n${width},${height}`;
    const label = new PIXI.Text(labelText, {
      fontFamily: "Arial",
      fontSize: fontSize,
      fill: 0,
    });
    label.x = x + 3;
    label.y = y + 3;

    box.addChild(label);
    return box;
  }

  private createAssets() {
    const { view } = this.app;

    const boxSize = Math.max(50, Math.floor(view.width / 100) * 10);

    // The border
    const BORDER_SIZE = 12;
    const BORDER_COLOR = 0xffafaf;
    this._root.addChild(
      new PIXI.Graphics()
        .beginFill(BORDER_COLOR)
        .drawRect(1, 1, view.width - 2, view.height - 2)
        .endFill()
        .beginHole()
        .drawRect(
          BORDER_SIZE / 2,
          BORDER_SIZE / 2,
          view.width - BORDER_SIZE,
          view.height - BORDER_SIZE
        )
        .endHole()
    );
    const label01 = new PIXI.Text(
      "The pink border should be\nexactly 1 pixel from each edge.",
      {
        fontFamily: "Arial",
        fontSize: 24,
        fill: 0xffffff,
      }
    );
    label01.position.set(boxSize + 20, 20);

    this._root.addChild(label01);

    // Top left sizing box
    this._root.addChild(
      this.labeledRectangle(10, 10, boxSize, boxSize, 0xffffff)
    );

    // Center box
    this._root.addChild(
      this.labeledRectangle(
        view.width / 2 - boxSize / 2,
        view.height / 2 - boxSize / 2,
        boxSize,
        boxSize,
        0xffffff,
        "center"
      )
    );
  }
}

export default ResizeTestScene;
