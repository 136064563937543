import * as PIXI from "pixi.js";
import {
  brickWall,
  pebbleTexture,
  wetRockTexture,
} from "../../../assets/loader";

export const createTilingSpriteFromResource =
  (texture: PIXI.BaseTexture) =>
  (width: number, height: number, textureScale: number) => {
    const sprite = new PIXI.TilingSprite(
      PIXI.Texture.from(texture),
      width,
      height
    );
    sprite.tileScale.set(textureScale);
    sprite.pivot.set(width / 2, height / 2);
    return sprite;
  };

export const pebbleSprite = (
  width: number,
  height: number,
  textureScale = 0.15
) =>
  createTilingSpriteFromResource(pebbleTexture.base)(
    width,
    height,
    textureScale
  );

export const createUndugFloorSprite = (
  width: number,
  height: number,
  textureScale = 0.15
) =>
  createTilingSpriteFromResource(wetRockTexture.base)(
    width,
    height,
    textureScale
  );

export const createBrickWallSprite = createTilingSpriteFromResource(brickWall);
