import { Scene } from "./Scene";
import * as PIXI from "pixi.js";
import * as AABB from "../../GameMath/aabb";
import { Corridor, DungeonMap, Room } from "../DungeonGeneration/dungeon";
import { IPoint } from "../../GameMath/point";

const ROUTE_COLOR = 0xff7f05; // Orange
const ROUTE_ALPHA = 0.8;
const ROUTE_LINE_SIZE = 3;
const ROUTE_NODE_RADIUS = 5;

const ROOM_BORDER_COLOR = 0xc04080; // Purple-ish
const ROOM_BORDER_ALPHA = 1;
const ROOM_BORDER_SIZE = 3;
const ROOM_COLOR = 0xffffff; // White
const ROOM_ALPHA = 0.5;

class DungeonDesignerScene implements Scene {
  private readonly _root: PIXI.Container;

  public constructor(private app: PIXI.Application) {
    this._root = new PIXI.Container();
  }

  public get root() {
    return this._root;
  }

  public activateScene() {}

  public deactivateScene() {}

  public drawMap(dungeon: { rooms: Room[]; corridors: Corridor[] }) {
    this._root.removeChildren();

    const zoom = this.zoomToFit(dungeon.rooms);
    const scale = zoom.scale;

    const g = new PIXI.Graphics();
    g.scale.set(zoom.scale / window.devicePixelRatio);
    g.transform.position.x = zoom.transformX;
    g.transform.position.y = zoom.transformY;

    if (dungeon.rooms) {
      dungeon.rooms.map((r) => this.makeRoom(r)).forEach((x) => g.addChild(x));

      dungeon.rooms
        .map((r) => this.makeRoomBorder(r, scale))
        .forEach((x) => g.addChild(x));
    }

    if (dungeon.corridors) {
      const pointsDrawn = new Set<IPoint>();
      dungeon.corridors
        .map((c) => this.makeConnection(c, pointsDrawn, scale))
        .forEach((x) => g.addChild(x));
    }

    this._root.addChild(g);
  }

  private zoomToFit(rooms: Room[]) {
    const width = this.app.view.height;
    const height = this.app.view.width;

    const extents = AABB.fromBoxes(
      rooms?.map((x) => x.box) ?? [AABB.create(-400, -400, 400, 400)]
    );

    const widthScale = height / extents.width;
    const heightScale = width / extents.height;
    const scale = Math.min(widthScale, heightScale) * 0.99;
    const transformY = -(extents.y + extents.height / 2) * scale + width / 2;
    const transformX = -(extents.x + extents.width / 2) * scale + height / 2;

    return {
      scale: scale,
      transformX: transformX / window.devicePixelRatio,
      transformY: transformY / window.devicePixelRatio,
    };
  }

  private makeRoom(room: Room) {
    return new PIXI.Graphics()
      .beginFill(ROOM_COLOR, ROOM_ALPHA)
      .drawPolygon(room.vertices.map((v) => new PIXI.Point(v.x, v.y)))
      .endFill();
  }

  private makeRoomBorder(room: Room, scale = 1) {
    const penSize = ROOM_BORDER_SIZE / scale;
    return new PIXI.Graphics()
      .lineStyle({
        width: penSize,
        color: ROOM_BORDER_COLOR,
        alpha: ROOM_BORDER_ALPHA,
      })
      .drawRect(
        room.box.x + penSize / 2,
        room.box.y + penSize / 2,
        room.box.width - penSize,
        room.box.height - penSize
      )
      .lineStyle();
  }

  private makeConnection(
    corridor: Corridor,
    pointsDrawn: Set<IPoint>,
    scale: number
  ) {
    const p1 = corridor.from.edge ?? corridor.from.room.center;
    const p2 = corridor.to.edge ?? corridor.to.room.center;

    const width =
      corridor?.width === 1 ? ROUTE_LINE_SIZE / scale : corridor.width;
    const drawPoints = corridor?.width === 1;

    const g = new PIXI.Graphics()
      .lineStyle({
        width,
        color: corridor?.width === 1 ? ROUTE_COLOR : 0x5522aa,
        alpha: ROUTE_ALPHA,
      })
      .moveTo(p1.x, p1.y)
      .lineTo(p2.x, p2.y)
      .lineStyle();

    if (drawPoints) {
      g.beginFill(ROUTE_COLOR, ROUTE_ALPHA);

      if (!pointsDrawn.has(p1)) {
        g.drawCircle(p1.x, p1.y, ROUTE_NODE_RADIUS / scale);
        pointsDrawn.add(p1);
      }

      if (!pointsDrawn.has(p2)) {
        g.drawCircle(p2.x, p2.y, ROUTE_NODE_RADIUS / scale);
        pointsDrawn.add(p2);
      }

      g.endFill();
    }

    const debugPoint = corridor?.debug?.points ?? [];
    debugPoint.forEach((p) => {
      g.beginFill(0xff0000)
        .drawCircle(p.x, p.y, ROUTE_NODE_RADIUS / scale)
        .endFill();
    });

    const debugLine = corridor?.debug?.lines ?? [];
    debugLine.forEach((l) => {
      g.lineStyle({ width, color: 0xff0000 })
        .moveTo(l.p1.x, l.p1.y)
        .lineTo(l.p2.x, l.p2.y)
        .lineStyle();
    });

    return g;
  }
}

export default DungeonDesignerScene;
