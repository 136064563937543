import { Vertex, Polygon } from "./types";

/**
 * Returns the center of mass of the polygon (weighted)
 */
export function centroid(vertices: Polygon): Vertex {
  let a = 0,
    x = 0,
    y = 0,
    l = vertices.length;

  for (let i = 0; i < l; i++) {
    const s = i === l - 1 ? 0 : i + 1;
    const v0 = vertices[i];
    const v1 = vertices[s];
    const f = v0.x * v1.y - v1.x * v0.y;

    a += f;
    x += (v0.x + v1.x) * f;
    y += (v0.y + v1.y) * f;
  }

  const d = a * 3;

  return { x: x / d, y: y / d };
}

export function createRegularPolygon(
  sides: number,
  radius: number = 0,
  startAngle: number | undefined = undefined
): Vertex[] {
  sides = Math.max(3, sides);
  if (startAngle === undefined) {
    if (sides === 4) {
      startAngle = -Math.PI / 4;
    } else {
      startAngle = sides % 2 === 0 ? 0 : -Math.PI / 2;
    }
  }
  const angle = (2 * Math.PI) / sides;
  const vertices: Vertex[] = [];
  for (let i = 0; i < sides; ++i) {
    vertices.push({
      x: radius * Math.cos(startAngle + angle * i),
      y: radius * Math.sin(startAngle + angle * i),
    });
  }
  return vertices;
}
