import { Vector } from "./types";

const DEGREES_PER_RADIAN = 180 / Math.PI;
export const radToDeg = (radians: number) => radians * DEGREES_PER_RADIAN;
export const degToRad = (degrees: number) => degrees / DEGREES_PER_RADIAN;

/**
 * Create a new vector.
 * @param x
 * @param y
 * @returns A new vector
 */
export const create = (x: number = 0, y: number = 0): Vector => ({ x, y });

/**
 * In our coordinate system, +x is right, +y is down
 * Zero degree rotation is right, +rotation is clockwise
 */
export const Up = Object.freeze(create(0, -1));
export const Down = Object.freeze(create(0, 1));
export const Right = Object.freeze(create(1, 0));
export const Left = Object.freeze(create(-1, 0));
export const ZeroAngle = Object.freeze(create(0, 0));

export const fromAngle = (angle) => ({
  x: Math.sin(angle),
  y: -Math.cos(angle),
});

/**
 * Create a new vector with copies of the original x and y coordinates.
 * @returns A new vector
 */
export const clone: (v: Vector) => Vector = ({ x, y }) => ({ x, y });

/**
 * Add two vectors together. (A + B)
 * @returns A new vector
 */
export const add = (a: Vector, b: Vector): Vector => ({
  x: a.x + b.x,
  y: a.y + b.y,
});

/**
 * Subtract vector B from vector A. (A - B)
 * @returns A new vector
 */
export const subtract = (a: Vector, b: Vector): Vector => ({
  x: a.x - b.x,
  y: a.y - b.y,
});

/**
 * Multiply the vector by the scalar.
 * @returns A new vector
 */
export const multiply = (v: Vector, scalar: number): Vector => ({
  x: v.x * scalar,
  y: v.y * scalar,
});

/**
 * Scales the vector by the scalar. (alias for multiple)
 * @returns A new vector
 */
export const scale = (v: Vector, scalar: number): Vector => ({
  x: v.x * scalar,
  y: v.y * scalar,
});

/**
 * Divide the vector by the scalar.
 * @returns A new vector
 */
export const divide = (a: Vector, scalar: number): Vector => ({
  x: a.x / scalar,
  y: a.y / scalar,
});

/**
 * Negate the vector (point it in the opposite direction).
 * @returns A new vector
 */
export const negate: (v: Vector) => Vector = ({ x, y }) => ({ x: -x, y: -y });

/**
 * Get the magnitude (length) of the vector.
 * @returns The magnitude
 */
export const magnitude: (v: Vector) => number = ({ x, y }) =>
  Math.sqrt(x * x + y * y);

/**
 * Get the magnitude (avoiding sqrt) of the vector.
 * @returns The magnitude (squared)
 */
export const magnitudeSquared: (v: Vector) => number = ({ x, y }) =>
  x * x + y * y;

/**
 * Normalize the vector (set the magnitude to 1).
 * @returns A new vector
 */
export const normalize = (v: Vector): Vector => {
  const mag = magnitude(v);
  if (mag === 0) {
    return { x: 0, y: 0 };
  } else {
    return { x: v.x / mag, y: v.y / mag };
  }
};

/**
 * Get the vector perpendicular to the the vector.
 * @param negate Set true for opposite vector
 * @returns A new vector
 */
export const perp = (v: Vector, negate = false): Vector => {
  const n = negate === true ? -1 : 1;
  return { x: -v.y * n, y: v.x * n };
};

/**
 * Get the angle (in radians) of the vectors and the X axis.
 * @returns A new vector
 */
export const angleDifference = (a: Vector, b: Vector): number =>
  Math.atan2(b.y - a.y, b.x - a.x);

/**
 * Get the angle (in radians) of the vector and the X axis.
 * @returns A new vector
 */
export const angle = (v: Vector): number => angleDifference(v, Right);

/**
 * Make sure the angle is always between -PI and +PI
 * @returns The angle
 */
export const normalizeAngle = (radians: number) => {
  if (radians > Math.PI) return radians - 2 * Math.PI;
  else if (radians < -Math.PI) return radians + 2 * Math.PI;
  else return radians;
};

/**
 * Get the angle (in radians) between two vectors.
 * @returns A new vector
 */
export const angleBetween = (a: Vector, b: Vector): number =>
  Math.atan2(b.y, b.x) - Math.atan2(a.y, a.x);

/**
 * Get the cross-product of two vectors.
 * @returns A new vector
 */
export const cross = (a: Vector, b: Vector): Vector => ({
  x: a.x * b.y,
  y: a.y * b.x,
});

/**
 * Get the dot-product of two vectors.
 * @returns The dot product
 */
export const dot = (a: Vector, b: Vector): number => a.x * b.x + a.y * b.y;

/**
 * Rotate the vector about the origin (0,0).
 * @param angle Degrees in radians.
 * @returns A new vector
 */
export const rotate = (v: Vector, angle: number): Vector => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return {
    x: v.x * cos - v.y * sin,
    y: v.x * sin + v.y * cos,
  };
};

/**
 * Rotate the vector about the point.
 * @param angle Degrees in radians.
 * @param point The point the rotate about.
 * @returns A new vector
 */
export const rotateAbout = (
  v: Vector,
  angle: number,
  point: Vector
): Vector => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const x = point.x + ((v.x - point.x) * cos - (v.y - point.y) * sin);
  const y = point.y + ((v.x - point.x) * sin + (v.y - point.y) * cos);
  return { x, y };
};

/**
 * Get the cardinal direction (U/D/L/R) of the vector.
 * NOTE: Not sure this is correct, needs to be double-checked.
 */
export const direction = (v: Vector) =>
  ["Left", "Up", "Right", "Down"][
    Math.round((angleDifference(Up, v) * 4) / (2 * Math.PI) + 4) % 4
  ];

export const oppositeDirection = (direction: string) => {
  switch (direction) {
    case "Left":
      return "Right";
    case "Up":
      return "Down";
    case "Right":
      return "Left";
    case "Down":
      return "Up";
    default:
      return direction;
  }
};

export function turnTowards(v: Vector, target: Vector, rate?: number) {
  rate = rate ?? Number.MAX_VALUE;
  const diff = normalizeAngle(angleBetween(v, target));
  if (Math.abs(diff) > Number.EPSILON) {
    const newAngle = Math.sign(diff) * Math.min(rate, Math.abs(diff));
    return rotate(v, newAngle);
  } else {
    return v;
  }
}
